import * as React from 'react';

const AvatarIconUser = ({ className: classNameProp, size = 40 }) =>
  <svg className={classNameProp} width={size} height={size} viewBox="0 0 344 344" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M172 0C77.0261 0 0 77.0261 0 172C0 266.974 77.0261 344 172 344C266.974 344 344 266.974 344 172C344 77.0261 266.974 0 172 0ZM175.399 64.585C209.595 64.585 237.265 92.2545 237.265 126.451C237.265 160.647 209.595 188.316 175.399 188.316C141.203 188.316 113.534 160.647 113.534 126.451C113.534 92.2545 141.203 64.585 175.399 64.585ZM274.384 274.384C261.059 287.709 245.559 298.111 228.359 305.453C210.547 312.999 191.579 316.806 172 316.806C152.421 316.806 133.453 312.999 115.641 305.453C99.8008 298.723 85.4561 289.409 72.947 277.579C87.2917 234.817 127.742 203.953 175.399 203.953C221.221 203.953 260.311 232.438 276.084 272.685C275.54 273.228 274.996 273.84 274.384 274.384Z"
      fill="#017EFF"
    />
  </svg>;

export default AvatarIconUser;
