import Color from 'color';

const Primary = '#2CCD6C';
const Secondary = '#017EFF';

export const AppColors = {
  blueBase: '#5EA0EC',
  blueDark: '#416895',
  greyBase: '#9B9B9B',
  greyDark: '#606060',
  greyLight: '#F5F5F5',
  fontBase: '#2B2B2B',
  error: '#F64D4D',
  success: '#5D9E13',
  info: '#5EA0EC',
  warning: '#FFCA28',
  white: '#FFFFFF',
  actionDisabled: '#C2C1C1',
  primaryLight: Color(Primary).lighten(0.1).hex(),
  primaryMain: Primary,
  primaryDark: Color(Primary).darken(0.1).hex(),
  secondaryLight: Color(Secondary).lighten(0.1).hex(),
  secondaryMain: Secondary,
  secondaryDark: Color(Secondary).darken(0.1).hex(),
  linkColorMain: Secondary,
  linkColorDark: Color(Secondary).darken(0.1).hex(),
  orange: '#FFA800',
  green: Primary,
  blue: Secondary,
};

export const institutionalRequirements = [
  {
    name: 'allows_observational_studies',
    label: 'Allows observational studies',
  },
  {
    name: 'allows_training_cognitive_games',
    label: 'Allows training related to participating in cognitive games',
  },
  {
    name: 'allows_individual_seperate_from_group',
    label: 'Allows individual animals to voluntarily separate from group for cognitive games',
  },
  {
    name: 'allows_opportunistic_collection',
    label: 'Allows opportunistic collection of urine, hair, feces',
  },
  {
    name: 'allows_moved_enclosures',
    label: 'Allows animals to be moved between and within enclosures',
  },
  {
    name: 'allows_veterinary_sampling',
    label: 'Only allows biological sampling as part of routine veterinary care',
  },
  {
    name: 'allows_veterinary_anesthesia',
    label: 'Only allows research requiring anesthesia as part of routine veterinary care',
  },
  {
    name: 'allows_anesthesia_sampling',
    label: 'Allows biological sampling and research requiring anesthesia',
  },
  {
    name: 'allows_post_mortem_tissue_sample',
    label: 'Allows post mortem tissue sampling',
  },
];


export const recaptchaKey = '6Ldpf6QUAAAAAMxwxI9yqvYcDADtsVkMRFaTvk0D';
