import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {
  Redirect,
} from 'react-router-dom';

import Loading from '../../components/Loading';
import InstitutionForm from '../../components/InstitutionForm';
import LinkBack from '../../components/LinkBack';

function InstitutionEdit({ match: { params: { number: institutionId } }, user, institutionGetEdit, institutionUpdate }) {
  const [isLoading, setIsLoading] = useState(true);
  const [institutionForm, setInstitutionForm] = useState(null);

  useEffect(() => {
    institutionGetEdit(institutionId).then(({ value }) => {
      setInstitutionForm({
        ...value.institution,
        species_connections_attributes: value.institution.species,
      });
      setIsLoading(false);
    });
  }, []);

  if (isLoading) return <Loading isLoading />;

  if (institutionForm.user.id !== user.id) {
    return <Redirect
      to={{
        pathname: `/institution/${institutionForm.slug}`,
      }}
    />;
  }

  return (
    <Grid container>
      <Grid item sm={3} />
      <Grid item sm={6}>
        <LinkBack to={`/institution/${institutionForm.slug}`}>
          View institution
        </LinkBack>
        <Typography variant="h2">Institution settings</Typography>
          <InstitutionForm
            form={institutionForm}
            handleSubmit={institutionUpdate}
          />
      </Grid>
    </Grid>
  );
}

export default InstitutionEdit;
