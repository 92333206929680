import React from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import { Formik, Form } from 'formik';

import { postForgotPassword } from '../../api/authApi';

import Loading from '../../components/Loading';

const styles = {
  root: {
    marginTop: 100,
  },
  form: {
    marginTop: 15,
  },
};

const renderForm = (isSuccess, values, errors, handleChange) => (
  isSuccess ?
    <>
      <Typography variant="h2">Password reset email sent</Typography>
      <Typography variant="body1">If you have an account, instructions on how to reset your password were sent to that address.</Typography>
    </>
    :
    <Form>
      <Typography variant="h2">Reset your password</Typography>
      <Typography>Enter your email address and we will send you a link to reset your password</Typography>
      <FormControl margin="normal" required fullWidth>
        <TextField
          type="email"
          name="email"
          label="Email address"
          value={values.email}
          onChange={handleChange}
          error={!!errors.email}
          helperText={errors.email}
          variant="outlined"
        />
      </FormControl>
      <FormControl margin="normal" required>
        <Button
          type="submit"
          variant="contained"
          color="primary"
        >
          Send
        </Button>
      </FormControl>
    </Form>
);

function ForgotPasswordForm({ classes }) {
  return (
    <Grid container className={classes.root}>
      <Grid item sm={4} />
      <Grid item sm={4}>
        <Formik
          initialValues={{
            email: '',
          }}
          onSubmit={({ email }, { setStatus, setErrors, setSubmitting }) => {
            postForgotPassword(email).then(() => {
              setStatus({ success: 'Email sent !' });
              setSubmitting(false);
            }).catch((response) => {
              setErrors(response.errors);
              setSubmitting(false);
            });
          }}
        >
          {({
            errors,
            status,
            isSubmitting,
            values,
            handleChange,
          }) => (
            isSubmitting
              ? <Loading isLoading />
              : renderForm(status, values, errors, handleChange)
          )}
        </Formik>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(ForgotPasswordForm);
