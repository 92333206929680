import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { AppColors } from '../../constants';
import Avatar from '../Avatar';

const styles = {
  avatar: {
    float: 'left',
    // marginRight: 10,
    fontSize: 40,
    width: 40,
    height: 40,
  },
};

const AvatarWithLabel = ({ classes, color, label, imageUrl, type = 'USER' }) => (
  <Grid
    container
    alignItems="center"
    justify="flex-start"
    spacing={8}
  >
    <Grid item>
      <Avatar
        alt={label}
        className={classes.avatar}
        imageUrl={imageUrl}
        type={type}
      />
    </Grid>
    <Grid item xs>
      <Typography style={{ color }}>{label}</Typography>
    </Grid>
  </Grid>
);

AvatarWithLabel.defaultProps = {
  color: AppColors.greyDark,
};

export default withStyles(styles)(AvatarWithLabel);
