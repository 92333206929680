
import React, { useState } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Color from 'color';
import Typography from '@material-ui/core/Typography';

import { AppColors } from '../../constants';
import TablePagination from '../TablePagination';

const styles = {
  row: {
    padding: 30,
    transitionDuration: 300,
    '&:nth-child(2n + 1)': {
      backgroundColor: AppColors.greyLight,
    },
    '&:hover': {
      backgroundColor: Color(AppColors.greyLight).darken(0.03).hex(),
    },
  },
  emptyRow: {
    padding: 30,
    backgroundColor: AppColors.greyLight,
    textAlign: 'center',
  },
  emptyRowText: {
    fontWeight: 400,
  },
};

function ListTable({ classes, items, RowComponent, emptyItemMessage = 'No results' }) {
  const [page, setPage] = useState(0);

  function handleChangePage(_event, newPage) {
    setPage(newPage);
  }

  const rowsPerPage = 20;
  const displayItems = items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  if (items.length === 0) {
    return (
      <div className={classes.emptyRow}>
        <Typography className={classes.emptyRowText}>{emptyItemMessage}</Typography>
      </div>
    );
  }

  return (
    <div>
      {displayItems.length > 0 && displayItems.map(item =>
        <div key={item.id} className={classes.row}>
          <RowComponent
            classes={classes}
            item={item}
          />
        </div>,
      )}
      <TablePagination
        rows={items}
        page={page}
        onChangePage={handleChangePage}
        rowsPerPage={rowsPerPage}
      />
    </div>
  );
}

export default withStyles(styles)(ListTable);
