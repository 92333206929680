import React, { useState, useEffect, useMemo } from 'react';

import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import Loading from '../../components/Loading';
import ProjectListTable from '../../components/ProjectListTable';
import TabHeader from '../../components/TabHeader';

const styles = {
  proposeButton: {
    float: 'right',
  },
  projectsHeader: {
    paddingTop: 40,
  },
};

function orderProjectsByCollaboratorCount(displayProjects) {
  return displayProjects.sort((p2, p1) => (p2.collaborator_count > p1.collaborator_count ? -1 : 1));
}

function ProjectList({ classes, isLoading, projects, userProjects, userSignedIn, projectsGetAll }) {
  const [showClosed, setShowClosed] = useState(false);

  useEffect(() => { projectsGetAll(); }, []);

  function handleChange(event) {
    setShowClosed(event.target.checked);
  }

  const displayProjects = useMemo(() => (showClosed ? projects : projects.filter(p => p.open === true)), [showClosed, projects]);
  const displayProjectsOrdered = useMemo(() => orderProjectsByCollaboratorCount(displayProjects), [displayProjects]);

  return (
    <div>
      <Grid container alignItems="center">
        <Grid item sm={8} xs={12}>
          <TabHeader active="projects" />
        </Grid>
        <Grid item sm={4} xs={12}>
          {
            userSignedIn &&
              <Button
                className={classes.proposeButton}
                component={Link}
                to="/project/new"
                variant="contained"
                color="primary"
              >Propose Project</Button>
          }
        </Grid>
      </Grid>
      <Loading
        isLoading={isLoading}
      >
        {
          userProjects.length > 0 &&
            <div>
              <Typography variant="h6" color="inherit" className={classes.projectsHeader}>
                Your Projects
              </Typography>
              <ProjectListTable projects={userProjects} />
              <Typography variant="h6" color="inherit" className={classes.projectsHeader}>
                All Projects
              </Typography>
            </div>
        }
        <FormControlLabel
          control={
            <Checkbox checked={showClosed} onChange={handleChange} value="showClosed" />
          }
          label="Include closed projects"
        />
        <ProjectListTable projects={displayProjectsOrdered} />
      </Loading>
    </div>
  );
}

export default withStyles(styles)(ProjectList);
