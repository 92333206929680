import React, { useState, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Formik, Form } from 'formik';
import { Redirect } from 'react-router-dom';

import LinkBack from '../../components/LinkBack';
import Loading from '../../components/Loading';

function UserContact({ match: { params: { number: userId } }, userContact, userGet }) {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    userGet(userId).then(({ value }) => {
      setUser(value.user);
      setIsLoading(false);
    });
  }, []);

  if (isLoading) return <Loading isLoading />;

  return (
    <Formik
      initialValues={{
        user_id: userId,
      }}
      validate={(values) => {
        const errors = {};
        if (!values.body) {
          errors.body = 'Required';
        }
        return errors;
      }}
      onSubmit={(values, { setStatus }) => {
        userContact(userId, values).then(() => {
          setStatus({ success: 'Email sent !' });
        });
      }}
    >
      {({
        values,
        errors,
        handleChange,
        status,
      }) => (
        <Grid container>
          <Grid item sm={3} />

          <Grid item sm={6} xs={12}>
            {status &&
              <Redirect
                to={{
                  pathname: `/user/${userId}`,
                }}
              />
            }

            <LinkBack to={`/user/${userId}`}>
              Back to {user.name}
            </LinkBack>
              <Typography variant="h1">Message {user.name}</Typography>
            <Form>
              <FormControl margin="normal" required fullWidth>
                <TextField
                  multiline
                  name="body"
                  label="Message"
                  value={values.body}
                  onChange={handleChange}
                  error={!!errors.body}
                  helperText={errors.body}
                  variant="outlined"
                  rows={5}
                />
              </FormControl>
              <FormControl margin="normal" required>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Send Message
                </Button>
              </FormControl>
            </Form>
          </Grid>
        </Grid>
      )}
    </Formik>
  );
}

export default UserContact;
