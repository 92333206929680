import React from 'react';

import Typography from '@material-ui/core/Typography';

const ErrorPage404 = () => (
  <div style={{ padding: 20 }}>
    <Typography variant="h1">404: Page Not Found.</Typography>
    <p>We're sorry the page that you requested, could not be found. </p>
  </div>
);

export default ErrorPage404;
