import { connect } from 'react-redux';

import SpeciesForm from './SpeciesForm';

import {
  speciesSearch,
} from '../../actions/speciesActions';

const mapStateToProps = state => ({
  searchQuery: state.searchReducer.searchQuery,
});

const mapDispatchToProps = {
  speciesSearch,
};

export default connect(mapStateToProps, mapDispatchToProps)(SpeciesForm);
