import React, { Component } from 'react';
import { connect } from 'react-redux';

import ProjectForm from './ProjectForm';

import {
  speciesCategoriesGetAll,
} from '../../actions/speciesCategoryActions';

const mapStateToProps = state => ({
  ...state.speciesCategoryReducer,
});

const mapDispatchToProps = {
  speciesCategoriesGetAll,
};

class ProjectSpecies extends Component {
  componentDidMount() {
    this.props.speciesCategoriesGetAll();
  }

  render() {
    return (
      <ProjectForm
        {...this.props}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSpecies);
