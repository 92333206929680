import React, { useState, useEffect } from 'react';
import renderHTML from 'react-render-html';

import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import { Link as RouteLink } from 'react-router-dom';
import CheckIcon from '@material-ui/icons/Check';
import XIcon from '@material-ui/icons/Close';

import Avatar from '../../components/Avatar';
import Loading from '../../components/Loading';
import SpeciesList from '../../components/SpeciesList';
import UserListTable from '../../components/UserListTable';
import { institutionalRequirements, AppColors } from '../../constants';
import { getFullTitle } from '../../utils/title';

const styles = {
  root: {
    textDecoration: 'none',
    color: '#000',
  },
  avatar: {
    width: 160,
    height: 160,
  },
  subHeader: {
    paddingTop: 40,
  },
  speciesList: {
    paddingLeft: 18,
  },
  acceptIcon: {
    color: AppColors.primaryMain,
  },
  denyIcon: {
    color: AppColors.error,
  },
  listItem: {
    paddingTop: 2,
    paddingBottom: 2,
    marginLeft: '-3px',
  },
  listItemText: {
    padding: 0,
  },
  denyListItemText: {
    padding: 0,
    opacity: '.4',
  },
  listItemIcon: {
    marginRight: 10,
  },
};

const AcceptIcon = withStyles(styles)(({ classes }) => <CheckIcon className={classes.acceptIcon} />);
const DenyIcon = withStyles(styles)(({ classes }) => <XIcon className={classes.denyIcon} />);
const RequirementList = withStyles(styles)(({ classes, requirements, accept }) =>
  requirements.map(requirement =>
    <ListItem
      key={requirement.label}
      disableGutters
      classes={{ root: classes.listItem }}
    >
      <ListItemIcon classes={{ root: classes.listItemIcon }}>
        { accept ? <AcceptIcon /> : <DenyIcon /> }
      </ListItemIcon>
      <ListItemText
        classes={{ root: accept ? classes.listItemText : classes.denyListItemText }}
        primary={requirement.label}
      />
    </ListItem>,
  ));


function Institution({ match: { params: { number: institutionId } }, classes, userSignedIn, user, institutionGet }) {
  const [isLoading, setIsLoading] = useState(true);
  const [institution, setInstitution] = useState(null);

  useEffect(() => {
    institutionGet(institutionId).then(({ value }) => {
      setInstitution(value.institution);
      setIsLoading(false);
      document.title = getFullTitle(value.institution.name);
    });
  }, []);

  if (isLoading) return <Loading isLoading />;

  const allowedRequirements = institutionalRequirements.filter(requirement => institution[requirement.name]);
  const denyRequirements = institutionalRequirements.filter(requirement => !institution[requirement.name]);

  return (
    <div>
      <Grid container alignItems="center" justify="space-between" spacing={24}>
        <Grid item>
          <Grid container alignItems="center" spacing={24}>
            <Grid item>
              <Avatar
                alt={institution.name}
                className={classes.avatar}
                imageUrl={institution.image_url}
                type="INSTITUTION"
              />
            </Grid>
            <Grid item textAlign="right">
              <Typography variant="h1"> {institution.name} </Typography>
              <Typography>{institution.city}, {institution.state}</Typography>
              <Typography>{institution.country}</Typography>
              <Typography><Link
                href={institution.website_link}
                target="_blank"
                color="secondary"
              >{institution.website_link}</Link></Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          { userSignedIn &&
            user.id === institution.user.id ?
              <Button
                component={RouteLink}
                to={`/institution/${institution.slug}/edit`}
                variant="contained"
                color="primary"
              >Edit Institution</Button>
            :
              <Button
                component={RouteLink}
                to={`/institution/${institution.slug}/contact`}
                variant="contained"
                color="primary"
              >Contact Institution</Button>
          }
        </Grid>
      </Grid>
      <Grid container alignItems="center">
        <Grid item sm={12}>
          <div>
            <Typography variant="h6" className={classes.subHeader}>Description</Typography>
            <Typography variant="body1">{renderHTML(institution.description)}</Typography>
          </div>
          <div>
            <Typography variant="h6" className={classes.subHeader}>Access To</Typography>
            <SpeciesList species={institution.species} />
          </div>
          <div>
            <Typography variant="h6" className={classes.subHeader}>Institutional Opportunities</Typography>
            <List disablePadding>
              <RequirementList requirements={allowedRequirements} accept />
              <RequirementList requirements={denyRequirements} />
            </List>
          </div>
          <div>
            <Typography variant="h6" className={classes.subHeader}>Guidelines for use</Typography>
            <Typography variant="body1">{renderHTML(institution.guidelines)}</Typography>
          </div>
          <div>
            <Typography variant="h6" className={classes.subHeader}>Managers</Typography>
            <UserListTable users={[institution.user]} />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(Institution);
