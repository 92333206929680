import { connect } from 'react-redux';

import {
  searchGet,
  searchSet,
} from '../../actions/searchActions';

import Search from './Search';

const mapStateToProps = state => ({
  ...state.searchReducer,
});

const mapDispatchToProps = {
  searchGet,
  searchSet,
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
