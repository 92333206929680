import {
  getUser,
  getUsers,
  postUserContact,
} from '../api/userApi';

export const userGet = userId => dispatch =>
  dispatch({
    type: 'USER_GET',
    payload: getUser(userId),
  });

export const usersGetAll = () => dispatch =>
  dispatch({
    type: 'USER_GET_ALL',
    payload: getUsers(),
  });

export const userContact = (userId, body) => dispatch =>
  dispatch({
    type: 'USER_CONTACT',
    payload: postUserContact(userId, body),
  });
