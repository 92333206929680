import {
  deleteUserAuth,
  getUserAuth,
  getUserFull,
  postLogin,
  postUserCreate,
  putUser,
  putPasswordReset,
} from '../api/authApi';

export const checkAuth = () => dispatch =>
  dispatch({
    type: 'USER_AUTH',
    payload: getUserAuth(),
  });

export const alertSignInRequired = userSignedIn => dispatch =>
  dispatch({
    type: 'NOTIFY_SIGN_IN',
    payload: userSignedIn,
  });

export const submitLogin = (email, password) => dispatch =>
  dispatch({
    type: 'SUBMIT_LOGIN',
    payload: postLogin(email, password),
  }).then(() => dispatch(checkAuth()));

export const submitSignUp = user => dispatch =>
  dispatch({
    type: 'SUBMIT_SIGN_UP',
    payload: postUserCreate(user),
  }).then(() => dispatch(checkAuth()));

export const submitLogout = () => dispatch =>
  dispatch({
    type: 'SUBMIT_LOGOUT',
    payload: deleteUserAuth(),
  });

export const userGetFull = () => dispatch =>
  dispatch({
    type: 'USER_GET_FULL',
    payload: getUserFull(),
  });

export const submitUserEdit = user => dispatch =>
  dispatch({
    type: 'SUBMIT_USER_EDIT',
    payload: putUser(user),
  }).then(() => dispatch(checkAuth()));;

export const userResetPassword = ({ password, password_confirmation, reset_password_token }) => dispatch =>
  dispatch({
    type: 'USER_RESET_PASSWORD',
    payload: putPasswordReset(password, password_confirmation, reset_password_token),
  }).then(() => dispatch(checkAuth()));
