export const defaultState = {
  isLoading: false,
  userProjects: [],
  institutions: [],
  institution: {},
  form: {},
  errors: {},
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'INSTITUTION_GET_ALL_FULFILLED':
      return {
        ...state,
        institutions: action.payload.institutions,
        userProjects: action.payload.user_institutions,
        isLoading: false,
      };
    case 'INSTITUTION_GET_ALL_PENDING':
      return {
        ...state,
        isLoading: true,
      };
    case 'INSTITUTION_GET_FULFILLED':
      return {
        ...state,
        institution: action.payload.institution,
        form: action.payload.institution,
        isLoading: false,
      };
    case 'INSTITUTION_GET_PENDING':
      return {
        ...state,
        isLoading: true,
      };
    case 'INSTITUTION_CREATE_FULFILLED':
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
      };
    case 'INSTITUTION_CREATE_PENDING':
      return {
        ...state,
        isLoading: true,
      };
    case 'INSTITUTION_CREATE_REJECTED':
      return {
        ...state,
        isLoading: false,
      };
    case 'INSTITUTION_UPDATE_REJECTED':
      return {
        ...state,
        isLoading: false,
      };
    case 'INSTITUTION_UPDATE_FULFILLED':
      return {
        ...state,
        isSuccess: true,
      };
    case 'INSTITUTION_UPDATE_PENDING':
      return {
        ...state,
      };
    default:
      return state;
  }
};
