import { connect } from 'react-redux';

import {
  userGet,
} from '../../actions/userActions';

import User from './User';

const mapStateToProps = state => ({
  userSignedIn: state.userReducer.userSignedIn,
  currentUser: state.userReducer.user,
});

const mapDispatchToProps = {
  userGet,
};

export default connect(mapStateToProps, mapDispatchToProps)(User);
