import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const linkStyles = {
  fontWeight: 600,
  textDecoration: 'none',
  textTransform: 'uppercase',
};

const styles = {
  link: {
    ...linkStyles,
    transitionDuration: 300,
    '&:hover': {
      color: '#017EFF',
    },
  },
};

function TabHeader({ classes, active }) {
  const [value, setValue] = useState(active);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  return (
    <Tabs
      value={value}
      onChange={handleChange}
      indicatorColor="secondary"
      textColor="secondary"
    >
      <Tab label="Projects" value="projects" component={Link} to="/project" className={classes.link} />
      <Tab label="People" value="users" component={Link} to="/user" className={classes.link} />
      <Tab label="Institutions" value="institutions" component={Link} to="/institution" className={classes.link} />
    </Tabs>
  );
}

export default withStyles(styles)(TabHeader);
