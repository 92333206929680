import React, { useRef } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Link as DocLink } from '@material-ui/core';
import SaveAlt from '@material-ui/icons/SaveAlt';

import Link from '../../components/Link';

import CartoonImage from '../../images/assets/cartoon.jpg';
import QuestionMethodsImage from '../../images/assets/question-methods.png';
import MacLean2014Cover from '../../images/assets/MacLean2014Cover.png';
import MacLean2012Cover from '../../images/assets/MacLean2012Cover.png';
import MacLean2012 from '../../images/docs/MacLean2012.pdf';
import MacLean2014 from '../../images/docs/MacLean2014.pdf';

import { containerStyle } from '../../styles/container';

const styles = theme => ({
  container: {
    ...containerStyle(theme),
    flexGrow: 1,
  },
  heroText: {
    marginBottom: 10,
  },
  headerText: {
    marginBottom: 10,
    marginTop: 20,
  },
  bodyText: {
    marginBottom: 20,
    fontSize: 18,
    fontWeight: 300,
  },
  image: {
    marginBottom: 20,
  },
  coverPhoto: {
    width: '100%',
    boxShadow: '0px 17px 30px -15px rgba(0,0,0,0.2)',
  },
  paper: {
    height: 140,
    width: 100,
  },
  downloadIcon: {
    position: 'absolute',
    bottom: 20,
    right: 20,
    visibility: 'hidden',
    opacity: 0,
    transition: 'visibility 0s, opacity 0.3s linear',
  },
  download: {
    position: 'relative',
    '&:hover $downloadIcon': {
      visibility: 'visible',
      opacity: 1,
    },
  },
  videoContainer: {
    position: 'relative',
    width: '100%',
    height: 0,
    paddingBottom: '56.25%',
    marginBottom: 20,
  },
  video: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
});

const useScroll = () => {
  const ref = useRef(null);
  const executeScroll = () => {
    window.scrollTo(0, ref.current.offsetTop);
  };
  const htmlElementAttributes = { ref };

  return [executeScroll, htmlElementAttributes];
};

const DownloadIcon = ({ classes }) => (
  <div className={classes.downloadIcon}>
    <SaveAlt
      color="primary"
      fontSize="large"
    />
  </div>
);

function About({ classes }) {
  const [scrollToDocLinks, docLinksScrollRef] = useScroll();

  return (
    <div className={classes.container}>
      <div>
        <Grid container alignItems="center">
          <Grid item sm={2} />
          <Grid item sm={8}>
            <Typography variant="h1" className={classes.heroText}>Comparative Phylogenetic Psychology</Typography>
            <div className={classes.videoContainer}>
              <iframe className={classes.video} title="PhyloPsy Intro video" width="100%" height="auto" src="https://www.youtube.com/embed/tRC_VcpHOX0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
            </div>
            <Typography variant="body1" className={classes.bodyText}>
              We share the planet with other minds. Animal cognition researchers have revealed sophisticated intelligence – in some cases exceeding that observed in humans, appearing where we least expect, or taking on forms we ourselves cannot experience.
            </Typography>
            <Typography variant="body1" className={classes.bodyText}>
              For example, Clarke’s nutcrackers remember thousands of locations where they hid nuts for the winter; cephalopods can mimick the appearance of more fearsome animals or camouflage themselves against their background, and grouper and eels coordinate while hunting on coral reefs while client fish form reputations about the skills of cleaner wrasse. Dogs understand our vocalizations better than we understand theirs. Our close relatives chimpanzees perceive and remember the locations of Arabic numerals far faster than most humans. And while humans and chimpanzees exhibit both xenophobia and lethal aggression, our other closest relative – bonobos - are xenophilic and never murder. Other animals perceive the world using echolocation, thermoception, electroreception, and magnoreception - all of which are imperceptible to us.
            </Typography>
            <Typography variant="body1" className={classes.bodyText}>
              In the face of this diversity, the human mind is just one among many shaped by evolution to perceive, to feel, to want, to know and even to wonder. The question then becomes how diverse the minds are we share the planet with and how are different forms of intelligence shaped by the random and nonrandom forces of nature and nurture.
            </Typography>
            <Typography variant="body1" className={classes.bodyText}>
              Now, more than ever, the opportunity exists to answer our most theoretically urgent questions using the resources here at PhyloPsy (Phylogenetic Psychology). PhyloPsy.org is an online platform designed to be a market place of ideas that can facilitate large-scale collaborations among animal cognition researchers from all over the world. Here you can propose your latest research ideas, build a world class team and find the collaborative tools you will need for your team to succeed.
            </Typography>
            <Typography variant="body1" className={classes.bodyText}>
              A free market of Ideas, expertise, and resources will help us make discoveries together that otherwise would have been impossible.
            </Typography>
            <Typography variant="h2" className={classes.headerText}>Why Phylogenetic Psychology?</Typography>
            <Typography variant="body1" className={classes.bodyText}>
              Historically most researchers comparing cognition across species have either relied on 1) data from a few locally available species 2) meta-analyses or 3) proxies for cognition, like brain size. This has significantly limited our ability to map diverse forms of intelligence and test both cognitive and evolutionary hypotheses.
            </Typography>
            <Typography variant="body1" className={classes.bodyText}>
              In 2011 MacLean and colleagues proposed that it is now possible to establish the field of Comparative Phylogenetic Psychology.&nbsp;
              <Link
                onClick={scrollToDocLinks}
              >(See below)</Link>&nbsp;
              They pointed out that phylogenetic comparative methods have revolutionized evolutionary biology and the tools needed for a similar revolution in comparative psychology are now available.
            </Typography>
            <Typography variant="body1" className={classes.bodyText}>
              MacLean et al 2011 also provide an illustrated overview of evolutionary questions relevant to comparative psychology and phylogenetic comparative methods designed to address them.
            </Typography>
            <Grid container alignItems="center">
              <Grid item sm={2} />
              <Grid item sm={8}>
                <img src={QuestionMethodsImage} alt="Question methods" className={classes.image} />
              </Grid>
            </Grid>
            <ul>
              <li><Typography variant="body1" className={classes.bodyText}>The shaded circles in the <i>top panel</i> depict species similarity along a continuous quantitative dimension (e.g. percent correct responses).</Typography></li>
              <li><Typography variant="body1" className={classes.bodyText}>The leaf and fruit icons in the <i>second panel</i> represent different dietary strategies that could be tested for their association with performance on a cognitive task.</Typography></li>
              <li><Typography variant="body1" className={classes.bodyText}>The <i>third panel</i> shows the root node on a phylogeny, representing an extinct species for which the ancestral cognitive ability could be predicted using data from extant species along the tips of phylogeny.</Typography></li>
              <li><Typography variant="body1" className={classes.bodyText}>The <i>fourth panel</i> illustrates a scenario in which there are only cognitive data for two species in the phylogeny. Phylogenetic targeting facilitates the strategic choice of which additional species are most interesting to test in order to evaluate an evolutionary hypothesis.</Typography></li>
            </ul>
            <Typography variant="body1" className={classes.bodyText}>
              MacLean et al 2011 then uses phylogenetic logic to provide a solution to the age-old <i>Beach-Bitterman Trap</i> that is created by the difficulties of comparing species with completely different morphologies, motivations, and perceptual abilities.
            </Typography>
            <img src={CartoonImage} alt="Brain Gear" className={classes.image} />
            <Typography variant="body1" className={classes.bodyText}>
              A PhyloPsy approach can avoid the trap by using similar behavioral methods within clades (i.e. a group of closely related species) but allowing differences in methods across clades (i.e that might differ wildly in motivation, morphology or perception). Phylogenetic methods allow a cognitive researcher to use valid tasks for each clade being compared instead of requiring the use of identical tasks for all species tested.
            </Typography>
            <Typography variant="body1" className={classes.bodyText}>
              Finally, MacLean et al 2011 predicted that the first generation of comparative phylogenetic psychology studies will be most successful if they employ testing procedures that (1) minimize or eliminate the need for training, (2) require few trials/sessions per subject, and (3) are easily implemented with few methodological modifications across species.
            </Typography>
            <Typography variant="body1" className={classes.bodyText}>
              In 2014, as a proof of concept MacLean et al published a large-scale Phylogenetic Psychology study from an international collaboration of over 50 researchers who tested 567 individuals representing 36 species on two tasks measuring self-control.&nbsp;
              <Link
                onClick={scrollToDocLinks}
              >(See below)</Link>
            </Typography>
            <Typography variant="body1" className={classes.bodyText}>
              MacLean et al 2014 found that dietary breadth and absolute brain size were the best predictors of self-control levels across species, after they controlled for phylogeny. Given the emphasis that has been placed on the importance of social complexity in shaping brain evolution, this discovery was particularly surprising, and would not have been possible without comparative phylogenetic psychology.
            </Typography>
            <Typography variant="body1" className={classes.bodyText}>
              The challenges of conducting a project of this size included the time required to find and recruit collaborators from all over the world, standardizing methods across such a wide variety of species, differing areas of expertise in the researchers, and recruiting the phylogenetic skills necessary to analyze the results.
            </Typography>
            <Typography variant="body1" className={classes.bodyText}>
              The PhyloPsy website, funded by the Templeton World Charity Foundation, has been built to promote Phylogenetic Psychology and has been designed with the lessons of the first large-scale collaborations in mind. PhyloPsy.org has been built to streamline the process of recruiting collaborators, standardize methods, and to provide a road map to successful collaborations that will facilitate many more large-scale phylogenetic comparisons. We hope researchers at all career stages will benefit and we look forward to the future discoveries that would have been impossible otherwise.
            </Typography>
            <Typography variant="h2" className={classes.heroText}>Further Reading</Typography>
          </Grid>
        </Grid>
        <div {...docLinksScrollRef}>
          <Grid container justify="center" spacing={32}>
            <Grid item sm={4} className={classes.download}>
              <DocLink
                target="_blank"
                href={MacLean2012}
              >
                <img src={MacLean2012Cover} alt="MacLean 2012" className={classes.coverPhoto} />
                <DownloadIcon classes={classes} />
              </DocLink>
            </Grid>
            <Grid item sm={4} className={classes.download}>
              <DocLink
                target="_blank"
                href={MacLean2014}
              >
                <img src={MacLean2014Cover} alt="MacLean 2014" className={classes.coverPhoto} />
                <DownloadIcon classes={classes} />
              </DocLink>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(About);
