import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import { containerStyle } from '../../styles/container';

const styles = theme => ({
  container: {
    ...containerStyle(theme),
    paddingTop: 40,
    paddingBottom: 40,
  },
  link: {
    marginRight: 20,
  },
});

const Footer = ({ classes }) => (
  <div className={classes.container}>
    <Grid
      container
      alignItems="center"
      justify="space-between"
    >
      <Grid item sm={4}>
        <Link
          className={classes.link}
          href="https://docs.google.com/document/d/1Xr0kr_2bMQInSg-CLnMhJ0BxelvH7DSWa5heYIOeQwE/edit?ts=5cdb3651"
          target="_blank"
          color="secondary"
        >FAQ</Link>
        <Link
          className={classes.link}
          href="mailto:hello@phylopsy.org"
          target="_blank"
          color="secondary"
        >Support</Link>
        <Link
          className={classes.link}
          href="/about"
          color="secondary"
        >About</Link>
        <Link
          className={classes.link}
          href="/collaborate"
          color="secondary"
        >Collaborate</Link>
      </Grid>
      <Grid item sm={4}>
        <Typography align="center">
          Species list provided by&nbsp;
          <Link
            href="https://www.itis.gov"
            target="_blank"
            color="secondary"
          >ITIS</Link>
        </Typography>
      </Grid>
      <Grid item sm={4}>
        <Typography align="right">&copy; {new Date().getFullYear()}</Typography>
      </Grid>
    </Grid>
  </div>
);

export default withStyles(styles)(Footer);
