import * as React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import LinkIcon from '@material-ui/icons/Link';
import Grid from '@material-ui/core/Grid';

import { AppColors } from '../../constants';

const styles = {
  Link: {
    color: AppColors.linkColorMain,
    fontWeight: 400,
  },
  iconContainer: {
    paddingTop: 5,
  },
};

const ExternalLink = ({ classes, children, href }) =>
  <Grid container alignItems="center" spacing={8}>
    <Grid item>
      <div className={classes.iconContainer}>
        <LinkIcon />
      </div>
    </Grid>
    <Grid item>
      <Link
        className={classes.Link}
        href={href}
        target="_blank"
      >
        {children}
      </Link>
    </Grid>
  </Grid>;

export default withStyles(styles)(ExternalLink);
