import { connect } from 'react-redux';

import {
  checkAuth,
} from '../../actions/authActions';

import App from './App';

const mapStateToProps = state => ({
  ...state.userReducer,
});

const mapDispatchToProps = {
  checkAuth,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
