import React from 'react';

import { Link as MaterialLink } from '@material-ui/core';

import {
  Link as RouterLink,
} from 'react-router-dom';

import AvatarWithLabel from '../AvatarWithLabel';
import { AppColors } from '../../constants';

const UserLinkWithImage = ({ color, user }) => (
  <MaterialLink
    underline={color === AppColors.linkColorMain ? 'hover' : 'none'}
    component={props => <RouterLink
      style={{ color }}
      to={`/user/${user.slug}`}
      {...props}
    />}
  >
    <AvatarWithLabel
      label={user.name}
      imageUrl={user.image_url}
      color={color}
    />
  </MaterialLink>
);

UserLinkWithImage.defaultProps = {
  color: AppColors.linkColorMain,
};

export default UserLinkWithImage;
