import { connect } from 'react-redux';

import {
  institutionGet,
} from '../../actions/institutionActions';

import Institution from './Institution';

const mapStateToProps = state => ({
  ...state.institutionReducer,
  ...state.userReducer,
});

const mapDispatchToProps = {
  institutionGet,
};

export default connect(mapStateToProps, mapDispatchToProps)(Institution);
