import React, { useState, useEffect } from 'react';
import renderHTML from 'react-render-html';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import Link from '@material-ui/core/Link';
import { Link as RouteLink } from 'react-router-dom';
import Avatar from '../../components/Avatar';
import Loading from '../../components/Loading';
import ProjectListTable from '../../components/ProjectListTable';
import BadgePhylogeneticist from '../../components/BadgePhylogeneticist';
import InstitutionListTable from '../../components/InstitutionListTable';
import SpeciesList from '../../components/SpeciesList';

import { getFullTitle } from '../../utils/title';

const styles = {
  root: {
    textDecoration: 'none',
    color: '#000',
  },
  avatar: {
    width: 160,
    height: 160,
  },
  subHeader: {
    paddingTop: 40,
  },
};

function User({ match: { params: { number: userId } }, classes, userSignedIn, currentUser, userGet }) {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    userGet(userId).then(({ value }) => {
      setUser(value.user);
      setIsLoading(false);
      document.title = getFullTitle(value.user.name);
    });
  }, []);

  if (isLoading) return <Loading isLoading />;

  return (
    <div>
      <Grid container alignItems="center" justify="space-between" spacing={24}>
        <Grid item>
          <Grid container alignItems="center" spacing={24}>
            <Grid item>
              <Avatar
                alt={user.name}
                className={classes.avatar}
                imageUrl={user.image_url}
              />
            </Grid>
            <Grid item textAlign="right">
              <Typography variant="h1"> {user.name} {user.phylogeneticist && <BadgePhylogeneticist /> }</Typography>
              <Typography>{user.city}, {user.state}</Typography>
              <Typography>{user.country}</Typography>
              <Typography><Link
                href={user.info_link}
                target="_blank"
                color="secondary"
              >{user.info_link}</Link></Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {userSignedIn &&
            user.id === currentUser.id ?
              <Button
                component={RouteLink}
                to="/user/edit"
                variant="contained"
                color="default"
              >Account Settings</Button>
            :
              <Button
                component={RouteLink}
                to={`/user/${user.slug}/contact`}
                variant="contained"
                color="primary"
              >Contact</Button>
          }
        </Grid>
      </Grid>
      <Grid container alignItems="center">
        <Grid item sm={12}>
          <Typography variant="h6" className={classes.subHeader}>Bio</Typography>
          <Typography variant="body1">{renderHTML(user.description)}</Typography>
          <Typography variant="h6" className={classes.subHeader}>Access To</Typography>
          <SpeciesList species={user.species} />
          {user.institutions.length > 0 &&
            <>
              <Typography variant="h6" className={classes.subHeader}>Institutions Managed</Typography>
              <InstitutionListTable institutions={user.institutions} />
            </>
          }
          <Typography variant="h6" className={classes.subHeader}>Projects</Typography>
          <ProjectListTable projects={user.projects} emptyItemMessage="No projects" />
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(User);
