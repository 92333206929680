import { connect } from 'react-redux';

import {
  submitSignUp,
  userGetFull,
  submitUserEdit,
} from '../../actions/authActions';

import UserEditForm from './UserEditForm';

const mapStateToProps = state => ({
  ...state.userReducer,
});

const mapDispatchToProps = {
  submitSignUp,
  userGetFull,
  submitUserEdit,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserEditForm);
