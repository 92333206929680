import {
  getSpeciesCategories,
} from '../api/speciesCategoryApi';

// eslint-disable-next-line import/prefer-default-export
export const speciesCategoriesGetAll = () => dispatch =>
  dispatch({
    type: 'SPECIES_CATEGORY_GET_ALL',
    payload: getSpeciesCategories(),
  });
