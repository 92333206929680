import React, { useEffect, useState } from 'react';
import renderHTML from 'react-render-html';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

import Loading from '../../components/Loading';
import UserLinkWithImage from '../../components/UserLinkWithImage';
import Comments from '../../components/Comments';
import ProjectStatus from '../../components/ProjectStatus';
import CollaboratorProgressEdit from '../../components/CollaboratorProgressEdit';
import Collaborator from '../../components/Collaborator';
import { activeProjectCollaborators } from '../../utils/project';
import ProjectActionButton from './ProjectActionButton';
import ExternalLink from './ExternalLink';

import { getFullTitle } from '../../utils/title';
import { AppColors } from '../../constants';

const styles = theme => ({
  paper: {
    ...theme.mixins.gutters(),
    backgroundColor: AppColors.greyLight,
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    marginBottom: 20,
  },
  subHeader: {
    paddingTop: 40,
  },
  projectHeader: {
    paddingBottom: 40,
  },
  video: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  speciesList: {
    paddingLeft: 18,
    margin: 0,
  },
});

function Project({ match: { params: { number: projectId } }, classes, user, userSignedIn, projectGet, collaboratorLeave }) {
  const [isLoading, setIsLoading] = useState(true);
  const [project, setProject] = useState(null);

  async function getProject() {
    const { value } = await projectGet(projectId);
    setProject(value.project);
    setIsLoading(false);
    document.title = getFullTitle(value.project.name);
  }

  useEffect(() => {
    getProject();
  }, []);

  if (isLoading || !project.name) return <Loading isLoading />;

  function getUserCollaboration() {
    if (!userSignedIn) return null;
    return project.collaborators.find(collaborator =>
      collaborator.user.id === user.id &&
        collaborator.status !== 'pending',
    );
  }

  function shouldShowPublishWarning() {
    return !project.active && user && project.user.id === user.id;
  }

  async function leaveProject(collaboratorId) {
    await collaboratorLeave(collaboratorId);
    getProject();
  }

  const userCollaboration = getUserCollaboration();
  const projectCollaborators = activeProjectCollaborators(project.collaborators);

  return (
    <div>
      <div>
        { shouldShowPublishWarning() &&
            <Paper className={classes.paper} elevation={0}>
              <Typography variant="body1">
                This project has not been published. Verify all required fields have been filled out before publishing. Publishing will open this project up to collaborators.
              </Typography>
            </Paper>
        }
        <Grid container spacing={24} alignItems="center" className={classes.projectHeader}>
          <Grid item sm={8} xs={12}>
            <ProjectStatus open={project.open} />
            <Typography variant="h1"> {project.name} </Typography>
            <UserLinkWithImage user={project.user} />
          </Grid>
          <Grid item sm={4} xs={12} textAlign="right">
            <ProjectActionButton
              project={project}
              user={user}
              leaveProject={leaveProject}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={24}
        >
          <Grid item sm={8} xs={12}>
            <div>
              <Typography variant="h6" color="inherit">
                Looking For
              </Typography>
              <ul className={classes.speciesList}>
                {project.species_categories.map(s =>
                  <li key={s}>
                    <Typography variant="body1" color="inherit">
                      {s.name}
                    </Typography>
                  </li>,
                )}
              </ul>
            </div>
            <div>
              <Typography className={classes.subHeader} variant="h6" color="inherit">
                Description
              </Typography>
              <Typography variant="body1" color="inherit">
                {renderHTML(project.description)}
              </Typography>
            </div>
            {project.video_link &&
              <div className={classes.video}>
                <iframe
                  title={project.title}
                  id="ytplayer"
                  type="text/html"
                  width="640"
                  height="360"
                  src={project.video_link}
                  frameBorder="0"
                />
              </div>
            }
            <Hidden xsDown>
              <Comments
                showForm={userSignedIn}
                project={project}
                comments={project.comments}
              />
            </Hidden>
          </Grid>
          <Grid item sm={4} xs={12}>
            <div>
              <Typography variant="h6" color="inherit">
                Links
              </Typography>
              <div>
                <ExternalLink
                  href={project.memorandum_link}
                >MoU</ExternalLink>
              </div>
              <div>
                <ExternalLink
                  href={project.files_link}
                >Project Files</ExternalLink>
              </div>
              {userCollaboration &&
                <div>
                  <Typography className={classes.subHeader} variant="h6" color="inherit">
                    Your Progress
                  </Typography>
                  <CollaboratorProgressEdit
                    collaboration={userCollaboration}
                    onChange={getProject}
                  />
                </div>
              }
              <Typography className={classes.subHeader} variant="h6" color="inherit">
                {projectCollaborators.length} Collaborator{projectCollaborators.length !== 1 && 's'}
              </Typography>
              {projectCollaborators.map(collaborator =>
                <Collaborator key={collaborator.id} collaborator={collaborator} />,
              )}
            </div>
          </Grid>
        </Grid>
      </div>
      <Hidden smUp>
        <Comments
          showForm={userSignedIn}
          project={project}
          comments={project.comments}
        />
      </Hidden>
    </div>
  );
}

export default withStyles(styles)(Project);
