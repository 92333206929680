import * as React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import UserLinkWithImage from '../UserLinkWithImage';
import BadgePhylogeneticist from '../BadgePhylogeneticist';
import { speciesDisplay } from '../../utils/species';
import { AppColors } from '../../constants';

const styles = {
  root: {
    paddingBottom: 20,
    marginBottom: 20,
    borderBottom: '1px solid #DADADA',
    '&:last-child ': {
      borderBottom: 'none',
    },
  },
  collaborationName: {
    paddingTop: 10,
  },
  progress: {
    color: '#EFEFEF',
    fontWeight: 600,
    lineHeight: 1.8,
  },
  progressBar: {
    backgroundColor: '#EFEFEF',
    borderRadius: 5,
    height: 15,
    width: '100%',
  },
};

const progressColor = (progress) => {
  if (progress === 'Piloting') return AppColors.blue;
  if (progress === 'Collecting Data') return AppColors.orange;
  if (progress === 'Completed') return AppColors.green;
  return '#2CCD6C';
};
const progressWidth = (progress) => {
  if (progress === 'Piloting') return 33;
  if (progress === 'Collecting Data') return 66;
  if (progress === 'Completed') return 100;
  return 0;
};

const progressBarStyle = progress => ({
  width: `${progressWidth(progress)}%`,
  backgroundColor: progressColor(progress),
  borderRadius: 5,
  height: '100%',
});

const renderProgress = (classes, progress) =>
  <div>
    <Typography variant="body1">
      <div
        className={classes.progress}
        style={{ color: progressColor(progress) }}
      >{progress}</div>
    </Typography>
    <div className={classes.progressBar}><div style={progressBarStyle(progress)} /></div>
  </div>;

const Collaborator = ({ classes, collaborator, showAmount = false }) =>
  <div className={classes.root}>
    <Grid container justify="space-between" alignItems="center">
      <Grid item>
        <UserLinkWithImage user={collaborator.user} />
      </Grid>
      <Grid item>
        {collaborator.phylogeneticist &&
          <BadgePhylogeneticist />
        }
      </Grid>
    </Grid>
    { collaborator.phylogeneticist &&
      <>
      <Typography className={classes.collaborationName}>Phylogenetic  Analysis</Typography>
        { renderProgress(classes, collaborator.progress) }
      </>
    }
    { collaborator.species.map(connection =>
      <div key={connection.id}>
        <Typography className={classes.collaborationName}>{speciesDisplay(connection.name)}{showAmount ? ` - ${connection.amount}` : '' }</Typography>
        { renderProgress(classes, connection.progress) }
      </div>,
    )}
  </div>;

export default withStyles(styles)(Collaborator);
