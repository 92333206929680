

import React from 'react';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withStyles } from '@material-ui/core/styles';
import ReCAPTCHA from 'react-google-recaptcha';
import { Formik, Form } from 'formik';

import InputText from '../InputText';
import Loading from '../Loading';
import FileDrop from '../FileDrop';
import SpeciesForm from '../SpeciesForm';
import InputCheckbox from '../InputCheckbox';
import Select from '../Select';
import { countries, getRegions } from '../../utils/countries';

import {
  recaptchaKey,
} from '../../constants';

const styles = {
  header: {
    marginTop: 0,
  },
  passwordHeader: {
    marginTop: 8,
    marginBottom: 0,
  },
  subheader: {
    marginBottom: 15,
  },
  avatar: {
    backgroundColor: '#DEDEDE',
    color: '#898989',
    float: 'left',
    marginRight: 10,
    width: 60,
    height: 60,
  },
  icon: {
    fontSize: 65,
    marginTop: 20,
  },
};

const UserForm = ({ form, classes, handleSubmit, isNewUser }) => (
  <Formik
    initialValues={form || {
      name: '',
      email: '',
      password: '',
      password_confirmation: '',
      city: '',
      state: '',
      country: '',
      description: '',
      info_link: '',
      species_connections_attributes: [],
    }}
    onSubmit={(values, actions) => {
      handleSubmit(values).then(() => {
        actions.setSubmitting(false);
        actions.setStatus({});
      }).catch((response) => {
        actions.setSubmitting(false);
        if (response.errors) {
          const errors = {
            ...response.errors,
            species_connections_attributes: response.errors['species_connections.species'] || response.errors['species_connections.amount'],
          };
          actions.setStatus(errors);
        }
      });
    }}
  >
    {({
      isSubmitting,
      status: errors = {},
      values,
      handleChange,
      setFieldValue,
    }) => (
      isSubmitting ?
        <Loading isLoading />
        :
        <Form className={classes.form}>
          <Typography variant="subtitle1" className={classes.subheader}>* Required</Typography>
          <FileDrop
            form={values}
            handleFile={(files) => {
              setFieldValue('image', files[0]);
              setFieldValue('image_url', URL.createObjectURL(files[0]));
            }}
          />
          <InputText
            name="name"
            label="Full name*"
            value={values.name}
            onChange={handleChange}
            error={errors.name}
          />
          <InputText
            type="email"
            name="email"
            label="Email*"
            value={values.email}
            onChange={handleChange}
            error={errors.email}
          />
          <Select
            label="Country*"
            value={values.country}
            error={errors.country}
            name="country"
            handleChange={(select) => {
              handleChange(select);
              setFieldValue('state', undefined);
            }}
            options={[
              { value: 'United States', label: 'United States' },
              ...countries.map(option => ({
                value: option, label: option,
              }))]}
          />
          <Select
            label="State / Province / Region*"
            value={values.state}
            error={errors.state}
            name="state"
            handleChange={handleChange}
            disabled={!values.country}
            options={
              getRegions(values.country).map(option => ({
                value: option, label: option,
              }))}
          />
          <InputText
            name="city"
            label="City*"
            value={values.city}
            onChange={handleChange}
            error={errors.city}
          />
          <InputText
            multiline
            name="description"
            label="Short bio*"
            value={values.description}
            onChange={handleChange}
            error={errors.description}
            rows={5}
          />
          <InputText
            name="info_link"
            label="Relevant link*"
            value={values.info_link}
            onChange={handleChange}
            error={errors.info_link}
            helpText="e.g. Google Scholar, Research Gate, or lab site"
          />
          <FormControl margin="normal" error={!!errors.species_connections_attributes} required fullWidth>
            <Typography variant="h5" className={classes.header}>List species you have access to</Typography>
            {errors.species_connections_attributes &&
              <FormHelperText>Both species and amount are required</FormHelperText>
            }
            <SpeciesForm
              species={values.species_connections_attributes}
              handleChange={(species_connections) => {
                setFieldValue('species_connections_attributes', species_connections);
              }}
            />
          </FormControl>
          {!isNewUser &&
            <>
              <Typography variant="h5" className={classes.passwordHeader}>Change password</Typography>
              <InputText
                type="password"
                name="current_password"
                label="Current password"
                value={values.current_password}
                onChange={handleChange}
                error={errors.current_password}
              />
            </>
          }
          <InputText
            type="password"
            name="password"
            label={isNewUser ? 'Password*' : 'New password'}
            value={values.password}
            onChange={handleChange}
            error={errors.password}
          />
          <InputText
            type="password"
            name="password_confirmation"
            label={isNewUser ? 'Confirm password*' : 'Confirm new password'}
            value={values.password_confirmation}
            onChange={handleChange}
            error={errors.password_confirmation}
          />
          <InputCheckbox
            checked={values.phylogeneticist}
            onChange={handleChange}
            name="phylogeneticist"
            label="Do you have expertise in phylogenetic comparative methods?"
          />
          { isNewUser &&
            <div>
              <InputCheckbox
                checked={values.createInstitution}
                onChange={handleChange}
                name="createInstitution"
                label="Do you manage a captive population of animals for an institution?"
                helpText="e.g. sanctuary, zoo, field site, or captive population. If so, you will be prompted to create an institution profile on the next page."
              />
              <FormControl margin="normal" error={!!errors.recaptcha} required fullWidth>
                <ReCAPTCHA
                  sitekey={recaptchaKey}
                  onChange={(token) => {
                    setFieldValue('captcha_token', token);
                  }}
                />
                {errors.recaptcha &&
                  <FormHelperText>Captcha failed.  Please try again.</FormHelperText>
                }
              </FormControl>
            </div>
          }
          <FormControl margin="normal" required>
            <Button
              type="submit"
              variant="contained"
              color="primary"
            >
              {isNewUser ? 'Create' : 'Update'} Account
            </Button>
          </FormControl>
        </Form>
    )}
  </Formik>
);

export default withStyles(styles)(UserForm);
