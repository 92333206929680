

import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

const InputText = ({ helpText, error, rows, ...rest }) => (
  <FormControl margin="normal" required fullWidth>
    <TextField
      {...rest}
      multiline={!!rows}
      error={!!error}
      helperText={error ? error.join(', ') : helpText}
      variant="outlined"
      rows={rows}
    />
  </FormControl>
);

export default InputText;
