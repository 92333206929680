

import React from 'react';

import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { institutionalRequirements } from '../../constants';
import InputCheckbox from '../InputCheckbox';

const styles = {
  header: {
    fontSize: 18,
    fontWeight: 500,
    marginTop: 30,
  },
  label: {
    fontSize: 14,
  },
};

const InstitutionForm = ({ classes, values, handleChange }) =>
  <div>
    <Typography variant="h5" className={classes.header}>Institutional opportunities</Typography>
    {institutionalRequirements.map(requirement =>
      <InputCheckbox
        key={requirement.name}
        checked={values[requirement.name]}
        onChange={handleChange}
        name={requirement.name}
        label={requirement.label}
      />,
    )}
  </div>;

export default withStyles(styles)(InstitutionForm);
