

import React from 'react';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import { Formik, Form, FieldArray } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';

import InputText from '../InputText';
import InputCheckbox from '../InputCheckbox';
import Loading from '../Loading';

const styles = {
  header: {
    fontSize: 18,
    fontWeight: 500,
    marginTop: 10,
  },
};

const FAQLink = () =>
  <Link
    target="_blank"
    color="secondary"
    underline="hover"
    href="https://docs.google.com/document/d/1Xr0kr_2bMQInSg-CLnMhJ0BxelvH7DSWa5heYIOeQwE/edit?ts=5cdb3651"
  >
    More info
  </Link>;

const ProjectForm = ({ classes, isNewProject, form, errors, handleSubmit, speciesCategories }) => (
  <Formik
    initialValues={form || {
      name: '',
      description: '',
      video_link: '',
      info_link: '',
      memorandum_link: '',
      files_link: '',
      project_management_link: '',
      species_category_ids: [],
    }}
    onSubmit={(values, actions) => {
      handleSubmit(values).then(() => {
        actions.setSubmitting(false);
      }).catch(() => {
        actions.setSubmitting(false);
      });
    }}
  >
    {({
      isSubmitting,
      values,
      handleChange,
    }) => (
      isSubmitting ?
        <Loading isLoading />
        :
        <Form>
          <Typography variant="subtitle1" className={classes.subheader}>* Required { !values.active && 'before project goes live' }</Typography>
          <InputText
            name="name"
            label="Project name*"
            value={values.name}
            onChange={handleChange}
            error={errors.name}
          />
          <InputText
            rows={9}
            name="description"
            label="Project description*"
            value={values.description}
            onChange={handleChange}
            error={errors.description}
          />
          <InputText
            name="video_link"
            label="YouTube URL*"
            value={values.video_link}
            onChange={handleChange}
            error={errors.video_link}
            helpText={<>Must be a YouTube embed URL. <FAQLink /></>}
          />
          <InputText
            name="memorandum_link"
            label="MoU URL*"
            value={values.memorandum_link}
            onChange={handleChange}
            error={errors.memorandum_link}
            helpText={<>We recommend using a public Google Drive link. <Link
              target="_blank"
              color="secondary"
              underline="hover"
              href="/files/PhyloPsyMoUGuidelinesV1.2019.doc"
            >Download Template</Link>. <FAQLink /></>}
          />
          <InputText
            name="files_link"
            label="Project files URL*"
            value={values.files_link}
            onChange={handleChange}
            error={errors.files_link}
            helpText={<>We recommend using a private Google Drive link. <FAQLink /></>}
          />
          <InputText
            name="project_management_link"
            label="Project management URL*"
            value={values.project_management_link}
            onChange={handleChange}
            error={errors.project_management_link}
            helpText={<>e.g. Slack, Asana, Basecamp, etc. <FAQLink /></>}
          />
          <Typography variant="h5" className={classes.header}>What species are you trying to study?</Typography>
          <FieldArray
            name="species_category_ids"
            render={arrayHelpers =>
              speciesCategories.map(category =>
                <InputCheckbox
                  key={category.id}
                  margin="none"
                  checked={values.species_category_ids.includes(category.id)}
                  onChange={(e) => {
                    if (e.target.checked) arrayHelpers.push(category.id);
                    else {
                      const idx = values.species_category_ids.indexOf(category.id);
                      arrayHelpers.remove(idx);
                    }
                  }}
                  name={`species_${category.id}`}
                  label={category.name}
                />,
              )}
          />

          <FormControl margin="normal" required>
            <Button
              type="submit"
              variant="contained"
              color="primary"
            >
              {isNewProject ? 'Create' : 'Update'} Project
            </Button>
          </FormControl>
        </Form>
    )}
  </Formik>
);

export default withStyles(styles)(ProjectForm);
