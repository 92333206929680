import {
  searchSpecies,
} from '../api/speciesApi';

// eslint-disable-next-line import/prefer-default-export
export const speciesSearch = query => dispatch =>
  dispatch({
    type: 'SPECIES_SEARCH',
    payload: searchSpecies(query),
  });
