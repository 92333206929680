import {
  getSearchResults,
} from '../api/searchApi';

export const searchGet = search => dispatch =>
  dispatch({
    type: 'SEARCH_GET',
    payload: getSearchResults(search),
  });

export const searchSet = search => dispatch =>
  dispatch({
    type: 'SEARCH_SET',
    payload: search,
  });
