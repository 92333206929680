import React from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { Formik, Form } from 'formik';
import {
  Redirect,
} from 'react-router-dom';

import Loading from '../../components/Loading';

const styles = {
  root: {
    marginTop: 100,
  },
  form: {
    marginTop: 15,
  },
};

const renderForm = (isSuccess, values, errors, handleChange) => (
  isSuccess ?
    <Typography>Success</Typography>
    :
    <Form>
      <FormControl margin="normal" required fullWidth>
        <TextField
          type="password"
          name="password"
          label="Password"
          value={values.password}
          onChange={handleChange}
          error={!!errors.password}
          helperText={errors.password}
          variant="outlined"
        />
      </FormControl>
      <FormControl margin="normal" required fullWidth>
        <TextField
          type="password"
          name="password_confirmation"
          label="Password Confirmation"
          value={values.password_confirmation}
          onChange={handleChange}
          error={!!errors.password_confirmation}
          helperText={errors.password_confirmation}
          variant="outlined"
        />
      </FormControl>
      <FormControl margin="normal" required>
        <Button
          type="submit"
          variant="contained"
          color="primary"
        >
          Reset
        </Button>
      </FormControl>
    </Form>
);

const PasswordResetForm = ({ classes, userSignedIn, userAuthLoading, location, userResetPassword }) => {
  if (userSignedIn && !userAuthLoading) {
    return (
      <Redirect
        to={{
          pathname: '/project',
        }}
      />
    );
  }

  return (
    <Grid container className={classes.root}>
      <Grid item sm={4} />
      <Grid item sm={4}>
        <Typography variant="h2">Enter new password</Typography>
        <Formik
          initialValues={{
            password: '',
            password_confirmation: '',
            reset_password_token: (new URLSearchParams(location.search)).get('token'),
          }}
          onSubmit={(values, { setStatus, setErrors, setSubmitting }) => {
            userResetPassword(values).then(() => {
              setStatus({ success: true });
              setSubmitting(false);
            }).catch((response) => {
              setErrors(response.errors);
              setSubmitting(false);
            });
          }}
        >
          {({
            isSubmitting,
            status,
            errors,
            values,
            handleChange,
          }) => (
            isSubmitting
              ? <Loading isLoading />
              : renderForm(status, values, errors, handleChange)
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(PasswordResetForm);
