import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { withRouter } from 'react-router-dom';
import { Formik, Form } from 'formik';

const styles = theme => ({
  search: {
    marginRight: theme.spacing.unit * 2,
    marginLeft: theme.spacing.unit * 5,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    border: 'solid thin #777777',
    borderRadius: 3,
    [theme.breakpoints.up('md')]: {
      width: 400,
    },
  },
  inputRoot: {
    color: 'inherit',
    flex: 1,
  },
  inputInput: {
    paddingTop: 10,
    paddingRight: 12,
    paddingBottom: 10,
    paddingLeft: 12,
    transition: theme.transitions.create('width'),
    fontSize: 14,
  },
  iconButton: {
    padding: 8,
  },
  searchContainer: {
    width: 'calc(100% - 48px)',
  },
});

function SearchBar({ match: { params: { query = '' } }, classes, history }) {
  return (
      <Formik
        initialValues={{
          search: query,
        }}
        onSubmit={(values) => {
          history.push(`/search/${values.search}`);
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
        }) => (
          <Form>
            <div className={classes.search}>
              <div className={classes.searchContainer}>
                <InputBase
                  placeholder="Search projects, people, institutions, species..."
                  name="search"
                  value={values.search}
                  onChange={handleChange}
                  fullWidth
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                />
              </div>
              <IconButton onClick={handleSubmit} className={classes.iconButton} aria-label="Search">
                <SearchIcon />
              </IconButton>
            </div>
          </Form>
        )}
      </Formik>
  );
}

export default withRouter(withStyles(styles)(SearchBar));
