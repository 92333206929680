import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { speciesDisplay } from '../../utils/species';

const styles = {
  speciesList: {
    paddingLeft: 18,
    margin: 0,
  },
};

function SpeciesList({ classes, species }) {
  return (
    <ul className={classes.speciesList}>
      {species.map(s =>
        <li key={s}>
          <Typography variant="body1" color="inherit">
            {speciesDisplay(s.name)} ({s.amount})
          </Typography>
        </li>,
      )}
    </ul>
  );
}

export default withStyles(styles)(SpeciesList);
