import React from 'react';

const ErrorPage = () => (
  <div style={{ padding: 20 }}>
    <h2>Something went wrong.</h2>
    <h3>Try refreshing the page </h3>
    <p> Or return <a href="/project">Home</a></p>
  </div>
);

export default ErrorPage;
