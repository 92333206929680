import React from 'react';

import AvatarImage from '@material-ui/core/Avatar';

import AvatarIconUser from '../AvatarIconUser';
import AvatarIconInstitution from '../AvatarIconInstitution';

const AvatarIcon = ({ className, type }) => (
  type === 'USER' ?
    <AvatarIconUser
      className={className}
    />
    :
    <AvatarIconInstitution
      className={className}
    />
);

const Avatar = ({ className, imageUrl, alt, type = 'USER' }) => (
  imageUrl
    ?
      <AvatarImage
        alt={alt}
        className={className}
        src={imageUrl}
      />
    :
      <AvatarIcon
        className={className}
        type={type}
      />
);

export default Avatar;
