import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Comment from './Comment';
import CommentForm from './CommentForm';
import CommentList from './CommentList';

const styles = {
  threadComments: {
    paddingLeft: 40,
    marginBottom: 20,
    borderLeft: '1px solid #E3E3E3',
  },
  commentContainer: {
    borderBottom: '1px solid #E3E3E3',
    paddingBottom: 20,
    marginBottom: 20,
    '&:last-child ': {
      borderBottom: 'none',
    },
  },
};

const CommentThread = ({ classes, comment, handleNewComment, showForm }) =>
  <div className={classes.commentContainer}>
    <Comment comment={comment} />
    {comment.comments.length > 0 &&
      <div className={classes.threadComments}>
        <CommentList comments={comment.comments} />
      </div>
    }
    {showForm &&
      <div className={classes.replyButton}>
        <CommentForm
          isReply
          comment={comment}
          handleNewComment={handleNewComment}
        />
      </div>
    }
  </div>;

export default withStyles(styles)(CommentThread);
