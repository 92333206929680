import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const actionsStyles = theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing.unit * 2.5,
  },
});

function TablePaginationActions({ classes, count, page, rowsPerPage, onChangePage }) {
  function handleBackButtonClick(event) {
    onChangePage(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onChangePage(event, page + 1);
  }

  return (
    <div className={classes.root}>
      <Button
        color="primary"
        disabled={page === 0}
        onClick={handleBackButtonClick}
        aria-label="Previous Page"
        variant="outlined"
      >
        Previous
      </Button>
      <Button
        color="primary"
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        onClick={handleNextButtonClick}
        aria-label="Next Page"
        variant="outlined"
      >
        Next
      </Button>
    </div>
  );
}

export default withStyles(actionsStyles, { withTheme: true })(
  TablePaginationActions,
);
