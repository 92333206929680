import { connect } from 'react-redux';

import {
  institutionCreate,
} from '../../actions/institutionActions';

import InstitutionCreate from './InstitutionCreate';

const mapStateToProps = state => ({
  ...state.institutionReducer,
});

const mapDispatchToProps = {
  institutionCreate,
};

export default connect(mapStateToProps, mapDispatchToProps)(InstitutionCreate);
