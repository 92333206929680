import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Color from 'color';

import { AppColors } from '../../constants';

const styles = {
  button: {
    '&:hover': {
      backgroundColor: Color(AppColors.error).lighten(0.52).hex(),
    },
  },
};

function ButtonError({ classes, children, ...rest }) {
  return (
    <span style={{ color: AppColors.error }}>
      <Button
        {...rest}
        color="inherit"
        className={classes.button}
      >
        {children}
      </Button>
    </span>
  );
}

export default withStyles(styles)(ButtonError);
