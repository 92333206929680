import { connect } from 'react-redux';

import Header from './Header';

import {
  submitLogout,
} from '../../actions/authActions';

const mapStateToProps = state => ({
  ...state.userReducer,
  searchQuery: state.searchReducer.searchQuery,
});

const mapDispatchToProps = {
  submitLogout,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
