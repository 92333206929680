import { connect } from 'react-redux';

import {
  projectGet,
  projectUpdate,
} from '../../actions/projectActions';

import {
  collaboratorDestroy,
  collaboratorAccept,
} from '../../actions/collaboratorActions';

import ProjectEdit from './ProjectEdit';

const mapStateToProps = state => ({
  ...state.userReducer,
  ...state.projectReducer,
});

const mapDispatchToProps = {
  projectGet,
  projectUpdate,
  collaboratorDestroy,
  collaboratorAccept,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectEdit);
