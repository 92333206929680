import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import ErrorBoundary from 'react-error-boundary';

import Home from '../Home';
import About from '../About';
import Collaborate from '../Collaborate';
import ProjectList from '../ProjectList';
import Project from '../Project';
import SignInForm from '../SignInForm';
import SignUpForm from '../SignUpForm';
import ProjectContact from '../ProjectContact';
import ProjectCreate from '../ProjectCreate';
import ProjectEdit from '../ProjectEdit';
import ProjectJoin from '../ProjectJoin';
import ForgotPasswordForm from '../ForgotPasswordForm';
import PasswordResetForm from '../PasswordResetForm';
import User from '../User';
import UserList from '../UserList';
import UserContact from '../UserContact';
import UserEditForm from '../UserEditForm';
import Institution from '../Institution';
import InstitutionList from '../InstitutionList';
import InstitutionCreate from '../InstitutionCreate';
import InstitutionContact from '../InstitutionContact';
import InstitutionEdit from '../InstitutionEdit';
import Search from '../Search';
import ErrorPage from '../ErrorPage';
import ErrorPage404 from '../ErrorPage404';

import RouteWithTitle from './RouteWithTitle';
import Theme from './Theme';
import GlobalStyles from './GlobalStyles';

class App extends Component {
  componentWillMount() {
    this.props.checkAuth();
  }

  render() {
    const {
      userSignedIn,
    } = this.props;

    return (
      <ErrorBoundary FallbackComponent={ErrorPage}>
        <MuiThemeProvider theme={Theme}>
          <Router basename="/">
            <Switch>
              <RouteWithTitle
                exact
                path="/project"
                component={ProjectList}
                title="Projects"
              />
              <RouteWithTitle
                exact
                authenticate
                authenticated={userSignedIn}
                path="/project/new"
                component={ProjectCreate}
                title="Propose Project"
              />
              <RouteWithTitle
                exact
                path="/project/:number"
                component={Project}
                title="Project"
              />
              <RouteWithTitle
                exact
                authenticate
                authenticated={userSignedIn}
                path="/project/:number/edit"
                component={ProjectEdit}
                title="Edit Project"
              />
              <RouteWithTitle
                exact
                authenticate
                authenticated={userSignedIn}
                path="/project/:number/join"
                component={ProjectJoin}
                title="Join Project"
              />
              <RouteWithTitle
                exact
                authenticate
                authenticated={userSignedIn}
                path="/project/:number/contact"
                component={ProjectContact}
                title="Contact Project"
              />
              <RouteWithTitle
                exact
                path="/institution"
                component={InstitutionList}
                title="Institutions"
              />
              <RouteWithTitle
                exact
                authenticate
                authenticated={userSignedIn}
                path="/institution/new"
                component={InstitutionCreate}
                title="New Institution"
              />
              <RouteWithTitle
                exact
                path="/institution/:number"
                component={Institution}
                title="Institution"
              />
              <RouteWithTitle
                exact
                authenticate
                authenticated={userSignedIn}
                path="/institution/:number/contact"
                component={InstitutionContact}
                title="Contact Institution"
              />
              <RouteWithTitle
                exact
                authenticate
                authenticated={userSignedIn}
                path="/institution/:number/edit"
                component={InstitutionEdit}
                title="Edit Institution"
              />
              <RouteWithTitle
                exact
                authenticate
                authenticated={!userSignedIn}
                path="/password/forgot"
                component={ForgotPasswordForm}
                title="Forgot Password"
              />
              <RouteWithTitle
                exact
                path="/password/new"
                component={PasswordResetForm}
                title="Password Reset"
              />
              <RouteWithTitle
                exact
                redirectTo="/project"
                path="/sign-in"
                component={SignInForm}
                title="Sign In"
              />
              <RouteWithTitle
                exact
                path="/sign-up"
                component={SignUpForm}
                title="Sign Up"
              />
              <RouteWithTitle
                exact
                authenticate
                authenticated={userSignedIn}
                path="/user/edit"
                component={UserEditForm}
                title="Account Settings"
              />
              <RouteWithTitle
                exact
                path="/user"
                component={UserList}
                title="People"
              />
              <RouteWithTitle
                exact
                path="/user/:number"
                component={User}
                title="Person"
              />
              <RouteWithTitle
                exact
                authenticate
                authenticated={userSignedIn}
                path="/user/:number/contact"
                component={UserContact}
                title="Contact Person"
              />
              <RouteWithTitle
                exact
                path="/search/:query?"
                component={Search}
                title="Search"
              />
              <RouteWithTitle
                exact
                path="/about"
                marketing
                fullWidth
                component={About}
              />
              <RouteWithTitle
                exact
                path="/collaborate"
                marketing
                fullWidth
                component={Collaborate}
              />
              <RouteWithTitle
                exact
                path="/"
                marketing
                fullWidth
                component={Home}
              />
              <RouteWithTitle
                component={ErrorPage404}
                title="404"
              />
            </Switch>
          </Router>
        </MuiThemeProvider>
      </ErrorBoundary>
    );
  }
}

export default withStyles(GlobalStyles)(App);
