import {
  acceptCollaborator,
  createCollaborator,
  destroyCollaborator,
  editCollaborator,
} from '../api/collaboratorApi';

export const collaboratorCreate = collaborator => dispatch =>
  dispatch({
    type: 'COLLABORATOR_CREATE',
    payload: createCollaborator(collaborator),
  });

export const collaboratorDestroy = collaboratorId => dispatch =>
  dispatch({
    type: 'COLLABORATOR_DESTROY',
    payload: destroyCollaborator(collaboratorId),
  });

export const collaboratorLeave = collaboratorId => dispatch =>
  dispatch({
    type: 'COLLABORATOR_LEAVE',
    payload: destroyCollaborator(collaboratorId),
  });

export const collaboratorEdit = collaborator => dispatch =>
  dispatch({
    type: 'COLLABORATOR_EDIT',
    payload: editCollaborator(collaborator),
  });

export const collaboratorAccept = collaboratorId => dispatch =>
  dispatch({
    type: 'COLLABORATOR_ACCEPT',
    payload: acceptCollaborator(collaboratorId),
  });
