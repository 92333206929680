import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import Header from '../Header';
import Footer from '../../components/Footer';
import { containerStyle } from '../../styles/container';
import Notification from '../../components/Notification';

const styles = theme => ({
  container: {
    ...containerStyle(theme),
    flexGrow: 1,
  },
  site: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  content: {
    flex: 1,
  },
});

const Layout = ({ classes, backTo, children, hideSearch, marketing, fullWidth = false }) => (
  <div className={classes.site}>
    <Header backTo={backTo} hideSearch={hideSearch} marketing={marketing} />
    {fullWidth ?
      children
      :
      <Grid container className={classes.content}>
        <Grid item xs={12} spacing={16}>
          <div className={classes.container}>
            {children}
          </div>
        </Grid>
      </Grid>
    }
    <Notification />
    <Footer />
  </div>
);

export default withStyles(styles)(Layout);
