import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

const styles = {
  container: {
    minHeight: 300,
  },
};

const Loading = ({ classes, children, className, isLoading }) => (
  <div className={className}>
    {isLoading ?
      <Grid className={classes.container} container justify="center" alignItems="center">
        <Grid item>
          <CircularProgress size={50} />
        </Grid>
      </Grid>
      : children}
  </div>
);

Loading.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.array,
  ]).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

Loading.defaultProps = {
  className: '',
};

export default withStyles(styles)(Loading);
