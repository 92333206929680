import { connect } from 'react-redux';

import {
  submitSignUp,
} from '../../actions/authActions';

import SignUpForm from './SignUpForm';

const mapStateToProps = state => ({
  ...state.userReducer,
});

const mapDispatchToProps = {
  submitSignUp,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpForm);
