import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga';

import './index.css';
import App from './pages/App';
import registerServiceWorker from './registerServiceWorker';
import configureStore from './store';

ReactGA.initialize(process.env.REACT_APP_ENVIRONMENT === 'production' ? 'UA-140246693-3' : 'UA-140246693-2');

ReactDOM.render(
  <Provider store={configureStore()}>
    <App />
  </Provider>,
  document.getElementById('root'),
);
registerServiceWorker();
