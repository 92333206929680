import React, { useState } from 'react';

import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import CommentForm from './CommentForm';
import CommentThread from './CommentThread';

const styles = {
  topComment: {
    marginBottom: 20,
  },
  comments: {
    paddingTop: 40,
  },
  threadComments: {
    paddingLeft: 20,
    borderLeft: '1px solid #E3E3E3',
  },
  commentContainer: {
    borderBottom: '1px solid #E3E3E3',
    paddingBottom: 10,
    marginBottom: 20,
  },
};

function Comments({ classes, project, showForm, comments: initComments }) {
  const [comments, setComments] = useState(initComments);

  function handleNewComment(comment, commentId) {
    let newComments = [...comments];
    if (commentId) {
      const parentCommentIndex = newComments.findIndex(c => c.id === commentId);
      newComments[parentCommentIndex].comments = [...newComments[parentCommentIndex].comments, comment];
    } else {
      newComments = [...comments, comment];
    }
    setComments(newComments);
  }

  return (
    <div className={classes.comments}>
      <Typography variant="h6">{comments.length} Comment{comments.length !== 1 && 's'}</Typography>

      {showForm &&
        <div className={classes.topComment}>
          <CommentForm
            project={project}
            handleNewComment={handleNewComment}
          />
        </div>
      }
      {
        comments.length > 0 && comments.map(comment =>
          <CommentThread
            key={comment.id}
            comment={comment}
            showForm={showForm}
            handleNewComment={handleNewComment}
          />,
        )
      }
    </div>
  );
}

export default withStyles(styles)(Comments);
