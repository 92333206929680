import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Link from '../Link';

const styles = {
  root: {
    fontWeight: 500,
  },
};


const LinkBack = ({ children, to, classes, ...rest }) =>
  <Link className={classes.root} to={to} {...rest}>
    &larr; { children }
  </Link>;

export default withStyles(styles)(LinkBack);
