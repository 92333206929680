import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Chip from '@material-ui/core/Chip';

const styles = {
  chip: {
    backgroundColor: '#A448FF',
    color: 'white',
    fontWeight: 600,
    borderRadius: 5,
    cursor: 'inherit',
    height: 24,
    marginLeft: 10,
  },
  label: {
    paddingLeft: 8,
    paddingRight: 8,
  },
};

const BadgePhylogenticist = ({ classes }) => (
  <Chip label="PHYLOGENETICIST" classes={{ root: classes.chip, label: classes.label }} />
);

export default withStyles(styles)(BadgePhylogenticist);
