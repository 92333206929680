import {
  createFormData,
  destroy,
  get,
  post,
  put,
  stringifySpecies,
} from '.';

export const postLogin = (email, password) =>
  post('users/sign_in', { user: { email, password } });
export const postUserCreate = user => post('users', createFormData(stringifySpecies(user), 'user'));
export const getUserAuth = () => get('auth');
export const getUserFull = () => get('users/edit');
export const deleteUserAuth = () => destroy('users/sign_out')
  .catch((error) => { if (error.message !== 'Unexpected end of JSON input') Promise.reject(error); });
export const postForgotPassword = email => post('users/password', { user: { email } });
export const putPasswordReset = (password, password_confirmation, reset_password_token) =>
  put('users/password.json', { user: { reset_password_token, password, password_confirmation } });
export const putUser = user => put('users.json', createFormData(stringifySpecies(user), 'user'));
