import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import { Link as MaterialLink } from '@material-ui/core';

import {
  Link as RouterLink,
} from 'react-router-dom';

import { AppColors } from '../../constants';

const styles = {
  root: {
    '&:hover': {
      color: AppColors.linkColorDark,
    },
    color: AppColors.linkColorMain,
    cursor: 'pointer',
    textDecoration: 'none',
  },
};

const Link = ({ children, to: url, external = false, ...rest }) => {
  const props = rest;
  if (url) {
    if (external) {
      props.href = url;
    } else {
      props.component = childProps => <RouterLink to={url} {...childProps} />;
    }
  }

  return (
    <MaterialLink
      {...props}
    >
      {children}
    </MaterialLink>
  );
};

export default withStyles(styles)(Link);
