import { connect } from 'react-redux';

import {
  institutionContact,
} from '../../actions/institutionActions';

import InstitutionContact from './InstitutionContact';

const mapDispatchToProps = {
  institutionContact,
};

export default connect(null, mapDispatchToProps)(InstitutionContact);
