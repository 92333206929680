import React, { useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Avatar from '../../components/Avatar';
import { AppColors } from '../../constants';

const styles = {
  menuItem: {
    outline: 'none',
    textDecoration: 'none',
    color: 'inherit',
  },
  accountLabel: {
    paddingLeft: 10,
    fontSize: 16,
    fontWeight: 500,
    color: AppColors.linkColorMain,
  },
  iconButton: {
    borderRadius: 5,
    padding: 5,
  },
};

function SimpleMenu({ classes, user, submitLogout }) {
  const [anchorEl, setAnchorEl] = useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function logoutClick() {
    handleClose();
    submitLogout();
  }

  return (
    <div>
      <IconButton
        aria-owns={anchorEl ? 'menu-appbar' : undefined}
        aria-haspopup="true"
        variant="extended"
        onClick={handleClick}
        color="inherit"
        className={classes.iconButton}
      >
        <Avatar
          alt={user.name}
          className={classes.avatar}
          imageUrl={user.image_url}
        />
        <Typography className={classes.accountLabel}>Account</Typography>
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Link to={`/user/${user.slug}`} className={classes.menuItem}>
          <MenuItem onClick={handleClose}>Profile</MenuItem>
        </Link>
        <Link to="/user/edit" className={classes.menuItem}>
          <MenuItem onClick={handleClose}>Account settings</MenuItem>
        </Link>
        <MenuItem onClick={logoutClick}>Sign out</MenuItem>
      </Menu>
    </div>
  );
}

export default withStyles(styles)(SimpleMenu);
