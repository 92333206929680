
import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Select from 'react-select/lib/Async';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import { speciesDisplay } from '../../utils/species';

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  input: {
    display: 'flex',
    padding: 0,
  },
  valueContainer: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
    paddingLeft: theme.spacing.unit * 2 - 2,
    paddingTop: 14,
    paddingBottom: 14,
  },
  noOptionsMessage: {
    fontSize: 12,
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  menuItem: {
    fontSize: 12,
    padding: 10,
    height: 'inherit',
  },
  placeholder: {
    position: 'absolute',
    fontSize: 16,
    left: theme.spacing.unit * 2,
  },
  paper: {
    position: 'absolute',
    zIndex: 2,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
});

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      Search for species by scientific or common name
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      variant="outlined"
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      className={props.selectProps.classes.menuItem}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  ValueContainer,
};

function SpeciesInput({ classes, species, speciesSearch, handleChange }) {
  function handleNameChange(newValue) {
    handleChange({
      ...species,
      name: newValue,
    });
  }

  function handleAmountChange(ev) {
    handleChange({
      ...species,
      amount: ev.target.value,
    });
  }

  function debounce(func, time = 1000) {
    let timer;
    return function foo(...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, time);
    };
  }

  function loadOptions(value, callback) {
    speciesSearch(value).then(response => response.value.species.map(s => ({
      value: s.id,
      label: speciesDisplay(s),
    }))).then(resp => callback(resp));
  }

  function getName() {
    if (species.name.label) {
      return { value: species.name, label: species.name.label };
    } else if (species.name) {
      return { value: species.name, label: species.name.latin_name };
    } else {
      return '';
    }
  }

  const name = getName();

  return (
    <FormControl margin="dense" variant="outlined" required fullWidth>
      <Grid container spacing={8}>
        <Grid item sm={8} xs={9}>
          <Select
            classes={classes}
            loadOptions={debounce(loadOptions)}
            components={components}
            value={name}
            onChange={handleNameChange}
            placeholder="Species"
          />
        </Grid>
        <Grid item sm={4} xs={3}>
          <TextField
            name="speciesAmount"
            label="How many?"
            value={species.amount}
            onChange={handleAmountChange}
            variant="outlined"
          />
        </Grid>
      </Grid>
    </FormControl>
  );
}


export default withStyles(styles)(SpeciesInput);
