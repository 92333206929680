import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Formik, Form } from 'formik';

import Loading from '../Loading';
import CollaboratorSpeciesProgressInput from './CollaboratorSpeciesProgressInput';
import Select from '../Select';

const styles = {
  submit: {
    height: 45,
  },
};

function CollaboratorProgressEdit({ classes, collaboration, collaboratorEdit, onChange }) {
  return (
    <Formik
      initialValues={collaboration}
      onSubmit={(values, actions) => {
        collaboratorEdit(values).then(() => {
          actions.setSubmitting(false);
          onChange();
        }).catch(() => {
          actions.setSubmitting(false);
        });
      }}
    >
      {({
        isSubmitting,
        values,
        handleChange,
        setFieldValue,
      }) => (
        isSubmitting ?
          <Loading isLoading />
          :
          <Form>
            {collaboration.phylogeneticist &&
              <FormControl margin="dense" variant="outlined" required fullWidth>
                <Typography>Phylogenetic  Analysis</Typography>
                <Grid container spacing={16} alignItems="center">
                  <Grid item sm={8}>
                    <FormControl variant="outlined" margin="dense" fullWidth required>
                      <Select
                        label="Progress*"
                        value={values.progress}
                        name="progress"
                        handleChange={handleChange}
                        options={['Not Started', 'In Progress', 'Completed'].map(option =>
                          ({ label: option, value: option }),
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={4}>
                    <Button
                      className={classes.submit}
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Update
                    </Button>
                  </Grid>
                </Grid>
              </FormControl>
            }
            {values.species.map((species, index) =>
              <CollaboratorSpeciesProgressInput
                key={species.id}
                species={species}
                handleChange={(value) => {
                  const newSpecies = {
                    ...species,
                    progress: value,
                  };
                  setFieldValue(`species[${index}`, newSpecies);
                }}
              />,
            )}
          </Form>
      )}
    </Formik>
  );
}

export default withStyles(styles)(CollaboratorProgressEdit);
