import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';

import Loading from '../../components/Loading';
import UserListTable from '../../components/UserListTable';
import ProjectListTable from '../../components/ProjectListTable';
import InstitutionTable from '../../components/InstitutionListTable';

const styles = {
  header: {
    marginBottom: 0,
    marginRight: 15,
  },
  subHeader: {
    marginTop: 40,
  },
  checkBoxRoot: {
    padding: 6,
    marginLeft: 24,
  },
};

function Search({ match: { params: { query } }, classes, isLoading, users, projects, institutions, searchGet }) {
  const [showInstitutions, setShowInstitutions] = useState(true);
  const [showProjects, setShowProjects] = useState(true);
  const [showUsers, setShowUsers] = useState(true);

  useEffect(() => { searchGet(query); }, [query]);

  function handleShowInstitutions({ target: { checked } }) {
    setShowInstitutions(checked);
  }

  function handleShowProjects({ target: { checked } }) {
    setShowProjects(checked);
  }

  function handleShowUsers({ target: { checked } }) {
    setShowUsers(checked);
  }

  return (
    <div>
      <Loading
        isLoading={isLoading}
      >
        <Grid
          container
          alignItems="center"
          justify="flex-start"
        >
          <Grid item>
            <Typography variant="h6" className={classes.header}>Search Results</Typography>
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showProjects}
                  onChange={handleShowProjects}
                  value="showProjects"
                  classes={{
                    root: classes.checkBoxRoot,
                  }}
                />
              }
              label="Show projects"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={showUsers}
                  onChange={handleShowUsers}
                  value="showUsers"
                  classes={{
                    root: classes.checkBoxRoot,
                  }}
                />
              }
              label="Show people"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={showInstitutions}
                  onChange={handleShowInstitutions}
                  value="showInstitutions"
                  classes={{
                    root: classes.checkBoxRoot,
                  }}
                />
              }
              label="Show institutions"
            />
          </Grid>
        </Grid>
        <Collapse in={showProjects}>
          <div>
            <Typography variant="h6" color="inherit" className={classes.subHeader}>
              Projects
            </Typography>
            <ProjectListTable projects={projects} />
          </div>
        </Collapse>
        <Collapse in={showUsers}>
          <div>
            <Typography variant="h6" color="inherit" className={classes.subHeader}>
              People
            </Typography>
            <UserListTable users={users} />
          </div>
        </Collapse>
        <Collapse in={showInstitutions}>
          <div>
            <Typography variant="h6" color="inherit" className={classes.subHeader}>
              Institutions
            </Typography>
            <InstitutionTable institutions={institutions} />
          </div>
        </Collapse>
      </Loading>
    </div>
  );
}

export default withStyles(styles)(Search);
