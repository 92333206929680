import { createMuiTheme } from '@material-ui/core/styles';
import { AppColors } from '../../constants';

export default createMuiTheme({
  palette: {
    action: {
      disabled: AppColors.actionDisabled,
      disabledBackground: '#FAFAFA',
    },
    error: {
      main: AppColors.error,
    },
    primary: {
      contrastText: AppColors.white,
      dark: AppColors.primaryDark,
      light: AppColors.primaryLight,
      main: AppColors.primaryMain,
    },
    secondary: {
      contrastText: AppColors.white,
      dark: AppColors.secondaryDark,
      light: AppColors.secondaryLight,
      main: AppColors.secondaryMain,
      textDecoration: 'none',
    },
    text: {
      primary: AppColors.fontBase,
    },
  },
  overrides: {
    MuiAppBar: {
      root: {
        boxShadow: 'none',
      },
    },
    MuiButton: {
      root: {
        boxShadow: 'none',
      },
      contained: {
        boxShadow: 'none',
      },
    },
  },
  typography: {
    fontFamily: [
      'Work Sans',
      'sans-serif',
    ].join(','),
    lineHeight: 1.4,
    title: {
      fontWeight: 600,
    },
    h1: {
      fontSize: 33,
      fontWeight: 'bold',
      lineHeight: 1.6,
    },
    h2: {
      fontSize: 18,
      fontWeight: 'bold',
      lineHeight: 2,
    },
    h5: {
      fontSize: 18,
      fontWeight: 500,
      marginTop: 30,
      marginBottom: 10,
    },
    h6: {
      fontSize: 14,
      fontWeight: 'bold',
      textTransform: 'uppercase',
      marginBottom: 15,
    },
    subtitle1: {
      color: '#9A9A9A',
    },
    body1: {
      fontSize: 16,
    },
  },
});
