export const defaultState = {
  users: [],
  projects: [],
  institutions: [],
  searchQuery: '',
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'SEARCH_GET_PENDING':
      return {
        ...state,
        isLoading: true,
      };
    case 'SEARCH_GET_FULFILLED':
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    case 'SEARCH_SET':
      return {
        ...state,
        searchQuery: action.payload,
      };
    default:
      return state;
  }
};
