import { connect } from 'react-redux';

import {
  userResetPassword,
} from '../../actions/authActions';

import PasswordResetForm from './PasswordResetForm';

const mapStateToProps = state => ({
  ...state.userReducer,
});

const mapDispatchToProps = {
  userResetPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordResetForm);
