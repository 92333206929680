import React, { useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import { createStyles, withStyles } from '@material-ui/core/styles';

import Loading from '../../components/Loading';
import UserListTable from '../../components/UserListTable';
import TabHeader from '../../components/TabHeader';

const styles = theme => createStyles({
  table: {
    paddingTop: theme.spacing.unit * 6,
  },
});

function UserList({ classes, isLoading, users, usersGetAll }) {
  useEffect(() => { usersGetAll(); }, []);

  return (
    <div>
      <Grid container alignItems="center">
        <Grid item>
          <TabHeader active="users" />
        </Grid>
      </Grid>
      <Loading
        isLoading={isLoading}
      >
        <div className={classes.table}>
          <UserListTable users={users} />
        </div>
      </Loading>
    </div>
  );
}

export default withStyles(styles)(UserList);
