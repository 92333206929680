

import React from 'react';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Formik, Form } from 'formik';

import Select from '../Select';
import Loading from '../Loading';
import FileDrop from '../FileDrop';
import SpeciesForm from '../SpeciesForm';
import InstitutionalRequirements from './InstitutionalRequirements';
import InputText from '../InputText';
import { countries, getRegions } from '../../utils/countries';

const InstitutionForm = ({ form, isNewInstitution, handleSubmit }) => (
  <Formik
    initialValues={form || {
      name: '',
      email: '',
      password: '',
      password_confirmation: '',
      city: '',
      state: '',
      country: '',
      description: '',
      info_link: '',
      species_connections_attributes: [],
    }}
    onSubmit={(values, actions) => {
      handleSubmit(values).then(() => {
        actions.setSubmitting(false);
        actions.setStatus({});
      }).catch((response) => {
        actions.setSubmitting(false);
        const errors = {
          ...response,
          species_connections_attributes: response['species_connections.species'] || response['species_connections.amount'],
        };
        actions.setStatus(errors);
      });
    }}
  >
    {({
      isSubmitting,
      status: errors = {},
      values,
      handleChange,
      setFieldValue,
    }) => (
      isSubmitting ?
        <Loading isLoading />
        :
        <Form>
          <Typography variant="subtitle1">* Required</Typography>
          <FileDrop
            form={values}
            handleFile={(files) => {
              setFieldValue('image', files[0]);
              setFieldValue('image_url', URL.createObjectURL(files[0]));
            }}
            type="INSTITUTION"
          />
          <InputText
            name="name"
            label="Institution name*"
            value={values.name}
            onChange={handleChange}
            error={errors.name}
          />
          <InputText
            type="email"
            name="email"
            label="Contact email for institution*"
            value={values.email}
            onChange={handleChange}
            error={errors.email}
          />
          <InputText
            name="website_link"
            label="Institution website*"
            value={values.website_link}
            onChange={handleChange}
            error={errors.website_link}
            helpText="Include full url. e.g. https://lemur.duke.edu/"
          />
          <Select
            label="Country*"
            value={values.country}
            error={errors.country}
            name="country"
            handleChange={(select) => {
              handleChange(select);
              setFieldValue('state', undefined);
            }}
            options={[
              { value: 'United States', label: 'United States' },
              ...countries.map(option => ({
                value: option, label: option,
              }))]}
          />
          <Select
            label="State / Province / Region*"
            value={values.state}
            error={errors.state}
            name="state"
            handleChange={handleChange}
            disabled={!values.country}
            options={
              getRegions(values.country).map(option => ({
                value: option, label: option,
              }))}
          />
          <InputText
            name="city"
            label="Institution city*"
            value={values.city}
            onChange={handleChange}
            error={errors.city}
          />
          <Select
            label="Institution Type*"
            value={values.institution_type}
            error={errors.institution_type}
            name="institution_type"
            handleChange={handleChange}
            options={[
              { value: 'Sanctuary', label: 'Sanctuary' },
              { value: 'Zoo', label: 'Zoo' },
              { value: 'Field Site', label: 'Field Site' },
              { value: 'University Laboratory', label: 'University Laboratory' },
              { value: 'Captive Population', label: 'Captive Population' },
              { value: 'Other', label: 'Other' },
            ]}
          />
          <InputText
            name="description"
            label="Institution description*"
            value={values.description}
            onChange={handleChange}
            error={errors.description}
            variant="outlined"
            rows={5}
          />
          <InputText
            name="guidelines"
            label="Guidelines for use*"
            value={values.guidelines}
            onChange={handleChange}
            error={errors.guidelines}
            rows={5}
          />
          <FormControl margin="dense" error={!!errors.species_connections_attributes} required fullWidth>
            <Typography variant="h5">List the species in your institution's population</Typography>
            {errors.species_connections_attributes &&
              <FormHelperText>Both species and amount are required</FormHelperText>
            }
            <SpeciesForm
              species={values.species_connections_attributes}
              handleChange={(species) => {
                setFieldValue('species_connections_attributes', species);
              }}
            />
          </FormControl>
          <InstitutionalRequirements values={values} handleChange={handleChange} />
          <FormControl margin="dense" required>
            <Button
              type="submit"
              variant="contained"
              color="primary"
            >
              {isNewInstitution ? 'Create' : 'Update'} Institution
            </Button>
        </FormControl>
      </Form>
    )}
  </Formik>
);

export default InstitutionForm;
