import { connect } from 'react-redux';

import {
  projectContact,
} from '../../actions/projectActions';

import ProjectContact from './ProjectContact';

const mapDispatchToProps = {
  projectContact,
};

export default connect(null, mapDispatchToProps)(ProjectContact);
