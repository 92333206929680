import { connect } from 'react-redux';

import Notification from './Notification';

import {
  closeNotification,
} from '../../actions/notificationActions';

const mapStateToProps = state => ({
  ...state.notificationReducer,
});

const mapDispatchToProps = {
  closeNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
