import countryData from './countryData.json';

export const getCountries = () =>
  countryData.map(c => c.countryName);

export const countries = getCountries();

export const getRegions = (countryName) => {
  const country = countryData.find(c => c.countryName === countryName);
  if (country) return country.regions.map(r => r.name);
  return [];
};
