import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {
  Redirect,
} from 'react-router-dom';

import ProjectForm from '../../components/ProjectForm';

const ProjectCreate = ({ isSuccess, errors, project, projectCreate }) => {
  if (isSuccess) {
    return (
      <Redirect
        to={{
          pathname: `/project/${project.slug}`,
        }}
      />
    );
  }

  return (
    <Grid container>
      <Grid item sm={3} />
      <Grid item sm={6}>
        <Typography variant="h2">Propose project</Typography>
        <ProjectForm
          errors={errors}
          handleSubmit={projectCreate}
          isNewProject
        />
      </Grid>
    </Grid>
  );
};

export default ProjectCreate;
