import { connect } from 'react-redux';

import SearchBar from './SearchBar';

import {
  searchSet,
} from '../../actions/searchActions';

const mapStateToProps = state => ({
  ...state.userReducer,
  searchQuery: state.searchReducer.searchQuery,
});

const mapDispatchToProps = {
  searchSet,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
