import {
  createInstitution,
  getInstitution,
  getInstitutionEdit,
  getInstitutions,
  updateInstitution,
  postInstitutionContact,
} from '../api/institutionApi';

export const institutionGet = institutionId => dispatch =>
  dispatch({
    type: 'INSTITUTION_GET',
    payload: getInstitution(institutionId),
  });

export const institutionGetEdit = institutionId => dispatch =>
  dispatch({
    type: 'INSTITUTION_GET_EDIT',
    payload: getInstitutionEdit(institutionId),
  });

export const institutionsGetAll = () => dispatch =>
  dispatch({
    type: 'INSTITUTION_GET_ALL',
    payload: getInstitutions(),
  });

export const institutionCreate = institution => dispatch =>
  dispatch({
    type: 'INSTITUTION_CREATE',
    payload: createInstitution(institution),
  });

export const institutionUpdate = institution => dispatch =>
  dispatch({
    type: 'INSTITUTION_UPDATE',
    payload: updateInstitution(institution),
  });

export const institutionContact = (institutionId, body) => dispatch =>
  dispatch({
    type: 'INSTITUTION_CONTACT',
    payload: postInstitutionContact(institutionId, body),
  });
