// eslint-disable-next-line import/prefer-default-export
export const containerStyle = theme => ({
  [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
    width: 1100,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  [theme.breakpoints.down(1100 + theme.spacing.unit * 3 * 2)]: {
    marginLeft: 15,
    marginRight: 15,
  },
});
