

import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  checkBoxroot: {
    padding: 5,
    marginLeft: 7,
  },
};

const InputCheckbox = ({ classes, name, error, margin = 'dense', label, helpText, ...rest }) => (
  <FormControl margin={margin} error={!!error} required fullWidth>
    <FormControlLabel
      control={
        <Checkbox
          value={name}
          name={name}
          classes={{
            root: classes.checkBoxroot,
          }}
          {...rest}
        />
      }
      label={label}
    />
    {helpText &&
      <FormHelperText>{helpText}</FormHelperText>
    }
    {error &&
      <FormHelperText>{error}</FormHelperText>
    }
  </FormControl>
);

export default withStyles(styles)(InputCheckbox);
