
import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';

import { speciesDisplay } from '../../utils/species';
import Select from '../Select';

const styles = {
  submit: {
    height: 45,
  },
};

function CollaboratorSpeciesProgressInput({ classes, species, handleChange }) {
  return (
    <FormControl margin="dense" variant="outlined" required fullWidth>
      <Typography>{speciesDisplay(species.name)}</Typography>
      <Grid container spacing={16} alignItems="center">
        <Grid item sm={8}>
          <FormControl key={species.id} variant="outlined" margin="dense" fullWidth required>
            <Select
              label="Progress*"
              value={species.progress}
              name="progress"
              handleChange={({ target: { value } }) => handleChange(value)}
              options={['Not Started', 'Piloting', 'Collecting Data', 'Completed'].map(option =>
                ({ label: option, value: option }),
              )}
            />
          </FormControl>
        </Grid>
        <Grid item sm={4}>
          <Button
            className={classes.submit}
            type="submit"
            variant="contained"
            color="primary"
          >
            Update
          </Button>
        </Grid>
      </Grid>
    </FormControl>
  );
}


export default withStyles(styles)(CollaboratorSpeciesProgressInput);
