export const defaultState = {
  isVisible: false,
  message: '',
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'CLOSE_NOTIFICATION':
      return {
        ...state,
        isVisible: false,
      };
    case 'NOTIFICATION_SERVER_ERROR':
      return {
        ...state,
        isVisible: true,
        message: 'Server error. Try refreshing the page.',
      };
    case 'NOTIFICATION_SERVER_ERROR_404':
      return {
        ...state,
        isVisible: true,
        message: 'Server error 404. Unknown request.',
      };
    case 'PROPOSAL_CREATE_FULFILLED':
      return {
        ...state,
        isVisible: true,
        message: 'Project created',
      };
    case 'PROPOSAL_UPDATE_FULFILLED':
      return {
        ...state,
        isVisible: true,
        message: 'Project saved',
      };
    case 'PROJECT_CONTACT_FULFILLED':
      return {
        ...state,
        isVisible: true,
        message: 'Message sent',
      };
    case 'SUBMIT_USER_EDIT_FULFILLED':
      return {
        ...state,
        isVisible: true,
        message: 'Settings saved',
      };
    case 'INSTITUTION_CREATE_FULFILLED':
      return {
        ...state,
        isVisible: true,
        message: 'Institution created',
      };
    case 'INSTITUTION_UPDATE_FULFILLED':
      return {
        ...state,
        isVisible: true,
        message: 'Institution saved',
      };
    case 'INSTITUTION_CONTACT_FULFILLED':
      return {
        ...state,
        isVisible: true,
        message: 'Message sent',
      };
    case 'SUBMIT_LOGOUT_FULFILLED':
      return {
        ...state,
        isVisible: true,
        message: 'Signed out',
      };
    case 'COLLABORATOR_CREATE_FULFILLED':
      return {
        ...state,
        isVisible: true,
        message: 'Request sent',
      };
    case 'COLLABORATOR_DESTROY_FULFILLED':
      return {
        ...state,
        isVisible: true,
        message: 'Removed collaborator from project',
      };
    case 'COLLABORATOR_LEAVE_FULFILLED':
      return {
        ...state,
        isVisible: true,
        message: 'Left project',
      };
    case 'COLLABORATOR_EDIT_FULFILLED':
      return {
        ...state,
        isVisible: true,
        message: 'Updated project status',
      };
    case 'COLLABORATOR_ACCEPT_FULFILLED':
      return {
        ...state,
        isVisible: true,
        message: 'Collaborator has been accepted to project',
      };
    case 'COMMENT_CREATE_FULFILLED':
      return {
        ...state,
        isVisible: true,
        message: 'Comment created',
      };
    case 'USER_CONTACT_FULFILLED':
      return {
        ...state,
        isVisible: true,
        message: 'Message sent',
      };
    case 'USER_RESET_PASSWORD_FULFILLED':
      return {
        ...state,
        isVisible: true,
        message: 'Password reset. Signed in.',
      };
    case 'NOTIFY_SIGN_IN':
      return {
        ...state,
        isVisible: true,
        message: action.payload
          ? 'Already signed in'
          : 'Sign in required before you can complete that action',
      };
    default:
      return state;
  }
};
