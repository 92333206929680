import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import UserLinkWithImage from '../UserLinkWithImage';

function formatDate(dateString) {
  const date = new Date(dateString);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours %= 12;
  hours = hours || 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  const strTime = `${hours}:${minutes} ${ampm}`;
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} at ${strTime}`;
}

const styles = {
  commentHeader: {
    paddingBottom: 10,
  },
  comment: {
    paddingBottom: 15,
    '&:last-child ': {
      paddingBottom: 0,
    },
  },
  timestamp: {
    fontWeight: 300,
    fontFamily: 'Work Sans',
  },
};

const Comment = ({ classes, comment }) =>
  <div className={classes.comment}>
    <Grid
      container
      justify="space-between"
      alignItems="center"
      className={classes.commentHeader}
    >
      <Grid item>
        <UserLinkWithImage user={comment.user} />
      </Grid>
      <Grid item>
        <Typography className={classes.timestamp}>{formatDate(comment.created_at)}</Typography>
      </Grid>
    </Grid>
    <Typography>{comment.body}</Typography>
  </div>;

export default withStyles(styles)(Comment);
