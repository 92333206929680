import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

import {
  Link as RouterLink,
} from 'react-router-dom';

import { AppColors } from '../../constants';
import Link from '../../components/Link';

const styles = theme => ({
  paper: {
    ...theme.mixins.gutters(),
    backgroundColor: AppColors.greyLight,
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    marginBottom: 20,
  },
  institutionTitle: {
    marginBottom: 0,
  },
  createInstitutionTitle: {
    color: '#017EFF',
    textTransform: 'none',
    fontWeight: 500,
  },
  body: {
    paddingBottom: 20,
  },
  institution: {
    marginTop: 10,
    marginBottom: 0,
  },
});

function UserInstitutions({ classes, institutions }) {
  return (
    !institutions.length ?
      <Paper className={classes.paper} elevation={0}>
        <Typography variant="h6" className={classes.createInstitutionTitle}>
          Do you represent an institution?
        </Typography>
        <Typography variant="body1" className={classes.body}>e.g. sanctuary, zoo, field site, university, lab, or capitive population</Typography>
        <Button
          component={RouterLink}
          to="/institution/new"
          variant="contained"
          color="secondary"
          fullWidth
        >Create Institution</Button>
      </Paper>
      :
      <Paper className={classes.paper} elevation={0}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h2" className={classes.institutionTitle}>
              Manage Institutions
            </Typography>
          </Grid>
          <Grid item>
            <Button
              component={Link}
              to="/institution/new"
              color="secondary"
              fullWidth
            > + Create Institution</Button>
          </Grid>
        </Grid>

        {institutions.map(institution =>
          <Typography key={institution.id} className={classes.institution} variant="h6">
            {institution.name}&nbsp;&nbsp;&nbsp;
            <Link
              to={`/institution/${institution.slug}/edit`}
              color="secondary"
              fullWidth
            >Edit </Link>
          </Typography>,
        )}
      </Paper>
  );
}

export default withStyles(styles)(UserInstitutions);
