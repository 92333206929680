import {
  getProjects,
  getProject,
  createProject,
  updateProject,
  postProjectContact,
} from '../api/projectApi';

export const projectsGetAll = () => dispatch =>
  dispatch({
    type: 'PROPOSAL_GET_ALL',
    payload: getProjects(),
  });

export const projectGet = projectId => dispatch =>
  dispatch({
    type: 'PROPOSAL_GET',
    payload: getProject(projectId),
  });

export const projectCreate = project => dispatch =>
  dispatch({
    type: 'PROPOSAL_CREATE',
    payload: createProject(project),
  });

export const projectUpdate = project => dispatch =>
  dispatch({
    type: 'PROPOSAL_UPDATE',
    payload: updateProject(project),
  });

export const projectContact = (projectId, body) => dispatch =>
  dispatch({
    type: 'PROJECT_CONTACT',
    payload: postProjectContact(projectId, body),
  });
