import { connect } from 'react-redux';

import CommentForm from './CommentForm';

import {
  commentCreate,
} from '../../../actions/commentActions';

import {
  projectGet,
} from '../../../actions/projectActions';

const mapDispatchToProps = {
  commentCreate,
  projectGet,
};

export default connect(null, mapDispatchToProps)(CommentForm);
