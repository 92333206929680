import React, { useState } from 'react';
import Button from '@material-ui/core/Button';

import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import Modal from '../Modal';
import ButtonError from '../../components/ButtonError';

const styles = {
  pending: {
    float: 'left',
    color: '#FFA800',
    textTransform: 'uppercase',
    fontWeight: 600,
    paddingRight: 20,
  },
};

const getCollaboration = (project, user) =>
  project.collaborators.find(collaborator => collaborator.user.id === user.id);

const ProjectActionButton = ({ classes, project, user, leaveProject }) => {
  const [leaveProjectModalOpen, setLeaveProjectModalOpen] = useState(false);

  if (!user) {
    return (
      <Button
        component={Link}
        to={`/project/${project.slug}/join`}
        variant="contained"
        color="primary"
      >Request to Join</Button>
    );
  }
  if (user.id === project.user.id) {
    return (
      <Button
        component={Link}
        to={`/project/${project.slug}/edit`}
        variant="contained"
      >Project Settings</Button>
    );
  }

  const collaboration = getCollaboration(project, user);
  if (collaboration) {
    if (collaboration.status === 'pending') {
      return (
        <Grid container alignItems="center">
          <Grid item>
            <Typography className={classes.pending}>Request Pending</Typography>
          </Grid>
          <Grid item>
            <Button
              component={Link}
              to={`/project/${project.slug}/contact`}
              variant="contained"
              color="secondary"
            >Contact Lead</Button>
          </Grid>
        </Grid>
      );
    } else {
      return (
        <>
          <ButtonError
            onClick={() => setLeaveProjectModalOpen(true)}
            variant="outlined"
          >Leave Project</ButtonError>

          <Modal
            open={leaveProjectModalOpen}
            handleClose={() => setLeaveProjectModalOpen(false)}
            title="Are you sure?"
            text="If you leave, you will no longer be able to collaborate on this project."
            cancelButton={
              <Button onClick={() => setLeaveProjectModalOpen(false)} color="secondary">
                Cancel
              </Button>}
            acceptButton={
              <Button
                onClick={() => {
                  leaveProject(collaboration.id);
                  setLeaveProjectModalOpen(false);
                }}
                color="secondary"
                variant="contained"
              >
                Leave Project
              </Button>}
          />
        </>
      );
    }
  }
  if (!project.open) {
    return (
      <Button
        component={Link}
        to={`/project/${project.slug}/contact`}
        variant="contained"
        color="primary"
      >Contact Lead</Button>
    );
  }
  return (
    <Button
      component={Link}
      to={`/project/${project.slug}/join`}
      variant="contained"
      color="primary"
    >Request to Join</Button>
  );
};

export default withStyles(styles)(ProjectActionButton);
