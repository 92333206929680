import React from 'react';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from './TablePaginationActions';

const Pagination = ({ rows = [], rowsPerPage, page, onChangePage }) => {
  if (rows.length < rowsPerPage) return null;
  return (
    <TablePagination
      ActionsComponent={TablePaginationActions}
      component="div"
      count={rows.length}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={[rowsPerPage]}
      page={page}
      backIconButtonProps={{
        'aria-label': 'Previous Page',
      }}
      nextIconButtonProps={{
        'aria-label': 'Next Page',
      }}
      onChangePage={onChangePage}
    />
  );
};

export default Pagination;
