import React, { useEffect } from 'react';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Redirect } from 'react-router-dom';
import FormControl from '@material-ui/core/FormControl';
import ExtenalLink from '@material-ui/core/Link';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withStyles } from '@material-ui/core/styles';
import { Formik, Form } from 'formik';

import LinkBack from '../../components/LinkBack';
import Loading from '../../components/Loading';
import SpeciesForm from '../../components/SpeciesForm';
import InputCheckbox from '../../components/InputCheckbox';
import InputText from '../../components/InputText';
import { AppColors } from '../../constants';

const styles = {
  Link: {
    color: AppColors.linkColorMain,
  },
};

function ProjectJoin({ classes, match: { params: { number: projectId } }, isLoading, project, projectGet, collaboratorCreate }) {
  useEffect(() => { projectGet(projectId); }, []);

  function handleSubmit(values, actions) {
    collaboratorCreate(values).then(() => {
      actions.setSubmitting(false);
      actions.setStatus({ success: 'Email sent !' });
    });
  }

  function handleValidate(values) {
    const errors = {};
    if (!values.description) {
      errors.description = ['Required'];
    }
    if (!values.agreeToMou) {
      errors.agreeToMou = 'Please agree to MOU';
    }
    if (values.species.length === 0 && !values.phylogeneticist) {
      errors.species = 'Please specify species or agree to phylogenetic analysis';
    }
    return errors;
  }

  if (isLoading) return <Loading isLoading />;

  return (
    <Grid container>
      <Grid item sm={3} />
      <Grid item sm={6} xs={12}>
        <LinkBack to={`/project/${project.slug}`}>
          Back to project
        </LinkBack>
        <Typography variant="h1">Request to join</Typography>
        <Typography variant="subtitle">The project creator must approve your request</Typography>
        <Formik
          initialValues={{
            project_id: project.id,
            description: '',
            species: [],
            agreeToMou: false,
            phylogeneticist: false,
          }}
          validate={handleValidate}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            isSubmitting,
            status,
            setFieldValue,
          }) => (
            isSubmitting ?
              <Loading isLoading />
              :
              <Form>
                {status &&
                  <Redirect
                    to={{
                      pathname: `/project/${project.slug}`,
                    }}
                  />
                }
                <InputText
                  name="description"
                  label="Explain why you want to collaborate on this project"
                  value={values.description}
                  onChange={handleChange}
                  error={(touched.description && errors.description)}
                  rows={5}
                />
                <FormControl margin="dense" error={!!errors.species} required fullWidth>
                  <SpeciesForm
                    species={values.species}
                    handleChange={(species) => {
                      setFieldValue('species', species);
                    }}
                  />
                  {(touched.species || touched.phylogeneticist) && errors.species &&
                    <FormHelperText>{errors.species}</FormHelperText>
                  }
                </FormControl>
                <InputCheckbox
                  checked={values.phylogeneticist}
                  onChange={handleChange}
                  name="phylogeneticist"
                  label="Are interested in doing phylogenetic analysis for this research?"
                />
                <InputCheckbox
                  checked={values.agreeToMou}
                  onChange={handleChange}
                  name="agreeToMou"
                  error={(touched.agreeToMou && errors.agreeToMou)}
                  label={
                    <Typography> I agree to the MoU (
                      <ExtenalLink
                        className={classes.Link}
                        href={project.memorandum_link}
                        target="_blank"
                      >
                        View
                      </ExtenalLink>
                    )
                    </Typography>}
                />
                <FormControl margin="normal" required>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Send Request
                  </Button>
                </FormControl>
              </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(ProjectJoin);
