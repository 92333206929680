import { connect } from 'react-redux';

import {
  submitLogin,
  alertSignInRequired,
} from '../../actions/authActions';

import SignInForm from './SignInForm';

const mapStateToProps = state => ({
  ...state.userReducer,
});

const mapDispatchToProps = {
  submitLogin,
  alertSignInRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignInForm);
