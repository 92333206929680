import React, { useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import { createStyles, withStyles } from '@material-ui/core/styles';

import Loading from '../../components/Loading';
import InstitutionListTable from '../../components/InstitutionListTable';
import TabHeader from '../../components/TabHeader';

const styles = theme => createStyles({
  table: {
    paddingTop: theme.spacing.unit * 6,
  },
});

function InstitutionList({ classes, isLoading, institutions, institutionsGetAll }) {
  useEffect(() => { institutionsGetAll(); }, []);

  return (
    <div>
      <Grid container alignItems="center">
        <Grid item>
          <TabHeader active="institutions" />
        </Grid>
      </Grid>
      <Loading
        isLoading={isLoading}
      >
        <div className={classes.table}>
          <InstitutionListTable institutions={institutions} />
        </div>
      </Loading>
    </div>
  );
}

export default withStyles(styles)(InstitutionList);
