
import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

const statusStyle = {
  borderRadius: 50,
  height: 12,
  width: 12,
  float: 'left',
  marginRight: 10,
};

const styles = {
  open: {
    ...statusStyle,
    backgroundColor: '#54EC3B',
  },
  closed: {
    ...statusStyle,
    backgroundColor: '#F64D4D',
  },
  text: {
    margin: 0,
    lineHeight: 1,
  },
};

const ProjectStatus = ({ classes, open }) =>
  <Grid container alignItems="center">
    <Grid item>
      <div className={open ? classes.open : classes.closed} />
    </Grid>
    <Grid item>
      {open ?
        <Tooltip title="Open for more collaborators" placement="bottom">
          <Typography className={classes.text} variant="h6">
            OPEN
          </Typography>
        </Tooltip>
        :
        <Tooltip title="Closed for more collaborators" placement="bottom">
          <Typography className={classes.text} variant="h6">
            CLOSED
          </Typography>
        </Tooltip>
      }
    </Grid>
  </Grid>;

export default withStyles(styles)(ProjectStatus);
