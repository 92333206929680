import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import {
  Redirect,
} from 'react-router-dom';
import Color from 'color';

import Loading from '../../components/Loading';
import Modal from '../Modal';
import ProjectForm from '../../components/ProjectForm';
import Collaborator from '../../components/Collaborator';
import LinkBack from '../../components/LinkBack';
import ButtonError from '../../components/ButtonError';
import { AppColors } from '../../constants';

const styles = {
  subHeader: {
    paddingTop: 40,
  },
  projectHeader: {
    paddingBottom: 40,
  },
  rejectButtonContainer: {
    marginTop: 10,
  },
  pendingCollaboratorContainer: {
    backgroundColor: Color(AppColors.orange).fade(0.9).string(),
    marginLeft: '-10px',
    marginTop: '-10px',
    marginBottom: 20,
    padding: 10,
    borderRadius: 5,
  },
};

function ProjectEdit({ match: { params: { number: projectId } }, user, classes, errors, projectGet, projectUpdate, collaboratorDestroy, collaboratorAccept }) {
  const [isLoading, setIsLoading] = useState(true);
  const [form, setForm] = useState(null);
  const [collaboratorId, setCollaboratorId] = useState(null);
  const [closeProjectModalOpen, setCloseProjectModalOpen] = useState(false);
  const [removeCollaboratorModalOpen, setRemoveCollaboratorModalOpen] = useState(false);

  function getProject() {
    projectGet(projectId).then(({ value }) => {
      setForm(value.project);
      setIsLoading(false);
    });
  }

  useEffect(() => { getProject(); }, []);

  function handleSubmit(values) {
    return projectUpdate({
      slug: form.slug,
      ...values,
    }).then(getProject);
  }

  function handleOpenChange(status) {
    return handleSubmit({
      open: status,
    });
  }

  function closeProjectModal() {
    setCloseProjectModalOpen(false);
  }

  function openProjectModal() {
    setCloseProjectModalOpen(true);
  }

  function removeCollaboratorModal(editCollaboratorId) {
    setCollaboratorId(editCollaboratorId);
    setRemoveCollaboratorModalOpen(true);
  }

  function removeCollaborator() {
    collaboratorDestroy(collaboratorId).then(getProject);
  }

  function acceptCollaborator(acceptCollaboratorId) {
    collaboratorAccept(acceptCollaboratorId).then(getProject);
  }

  function closeCollaboratorModal() {
    setCollaboratorId(null);
    setRemoveCollaboratorModalOpen(false);
  }

  if (isLoading) return <Loading isLoading />;

  if (form.user.id !== user.id) {
    return <Redirect
      to={{
        pathname: `/project/${form.slug}`,
      }}
    />;
  }

  const pendingCollaborators = form.collaborators.filter(c => c.status === 'pending');
  const collaborators = form.collaborators.filter(c => c.status !== 'pending');

  return (
    <div>
      <LinkBack to={`/project/${form.slug}`}>
        View project
      </LinkBack>
      <Typography variant="h2">Project settings</Typography>
      <Grid container spacing={24}>
        <Grid item sm={6} xs={12}>
          <ProjectForm
            form={form}
            errors={errors}
            handleSubmit={handleSubmit}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          { !form.active &&
            <Typography>Project is a draft. Fill out all fields and Publish to begin collaboration.</Typography>
          }
          { form.open ?
            <ButtonError
              onClick={() => openProjectModal()}
              variant="outlined"
            >
              Close Project
            </ButtonError>
            :
            <Button
              onClick={() => handleOpenChange(true)}
              color="primary"
              variant="contained"
            >
              { form.active ? 'Open' : 'Publish' } Project
            </Button>
          }
          <Typography variant="h6" className={classes.subHeader} color="inherit">
            {form.collaborators.length} Collaborators
          </Typography>
          {pendingCollaborators.map(collaborator =>
            <div key={collaborator.id} className={classes.pendingCollaboratorContainer}>
              <Grid container spacing={24}>
                <Grid item sm={9}>
                  <Collaborator collaborator={collaborator} showAmount />
                </Grid>
                <Grid item sm={3}>
                  <Grid container justify="center">
                    <Button
                      onClick={() => acceptCollaborator(collaborator.id)}
                      color="secondary"
                      variant="contained"
                    >
                      Accept
                    </Button>
                    <div className={classes.rejectButtonContainer}>
                      <ButtonError
                        onClick={() => removeCollaboratorModal(collaborator.id)}
                      >
                        Reject
                      </ButtonError>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </div>,
          )}
          {collaborators.map(collaborator =>
            <div key={collaborator.id}>
              <Grid container spacing={24}>
                <Grid item sm={9}>
                  <Collaborator collaborator={collaborator} showAmount />
                </Grid>
                <Grid item sm={3}>
                  <Grid container justify="center">
                    <ButtonError
                      onClick={() => removeCollaboratorModal(collaborator.id)}
                    >
                      Remove
                    </ButtonError>
                  </Grid>
                </Grid>
              </Grid>
            </div>,
          )}
        </Grid>
      </Grid>
      <Modal
        open={closeProjectModalOpen}
        handleClose={closeProjectModal}
        title="Are you sure?"
        text="If you close this project, you will not be able to add new collaborators. You and current collaborators can still collaborate on this project. You can also Open this project back up at any time."
        cancelButton={
          <Button onClick={closeProjectModal} color="secondary">
            Cancel
          </Button>}
        acceptButton={
          <Button
            onClick={() => {
              handleOpenChange(false);
              closeProjectModal();
            }}
            color="secondary"
            variant="contained"
          >
            Close Project
          </Button>}
      />
      <Modal
        open={removeCollaboratorModalOpen}
        handleClose={closeCollaboratorModal}
        title="Are you sure?"
        text="Are you sure you want to remove this collaborator?"
        cancelButton={
          <Button onClick={closeCollaboratorModal} color="secondary">
            Cancel
          </Button>}
        acceptButton={
          <Button
            onClick={() => {
              removeCollaborator();
              closeCollaboratorModal();
            }}
            color="secondary"
            variant="contained"
          >
            Remove Collaborator
          </Button>}
      />
    </div>
  );
}

export default withStyles(styles)(ProjectEdit);
