import { connect } from 'react-redux';

import {
  projectCreate,
} from '../../actions/projectActions';

import ProjectCreate from './ProjectCreate';

const mapStateToProps = state => ({
  ...state.projectReducer,
});

const mapDispatchToProps = {
  projectCreate,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectCreate);
