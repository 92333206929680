import * as React from 'react';

import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import UserLinkWithImage from '../UserLinkWithImage';
import ListTable from '../ListTable';
import BadgePhylogeneticist from '../BadgePhylogeneticist';
import { AppColors } from '../../constants';

const styles = {
  link: {
    textDecoration: 'none',
  },
};

const UserListTableRow = ({ classes, item }) =>
  <Link
    className={classes.link}
    to={`/user/${item.slug}`}
  >
    <Grid container spacing={8} key={item.id} justify="flex-start" alignItems="center">
      <Grid item>
        <UserLinkWithImage user={item} color={AppColors.greyDark} />
      </Grid>
      <Grid item>
        {item.phylogeneticist && <BadgePhylogeneticist />}
      </Grid>
    </Grid>
  </Link>;

const UserListTable = ({ classes, users }) =>
  <ListTable
    classes={classes}
    items={users}
    RowComponent={UserListTableRow}
  />;

export default withStyles(styles)(UserListTable);
