import * as React from 'react';

import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ListTable from '../ListTable';
import AvatarWithLabel from '../AvatarWithLabel';

const styles = {
  link: {
    color: 'inherit',
    textDecoration: 'none',
  },
};

const InstitutionListTableRow = ({ classes, item }) =>
  <Link
    className={classes.link}
    to={`/institution/${item.slug}`}
  >
    <Grid container spacing={24} key={item.id}>
      <Grid item>
        <AvatarWithLabel
          label={item.name}
          imageUrl={item.image_url}
          type="INSTITUTION"
        />
      </Grid>
    </Grid>
  </Link>;


const InstitutionListTable = ({ classes, institutions }) =>
  <ListTable
    classes={classes}
    items={institutions}
    RowComponent={InstitutionListTableRow}
  />;

export default withStyles(styles)(InstitutionListTable);
