
import React from 'react';

import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PersonIcon from '@material-ui/icons/Person';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';

import UserLinkWithImage from '../UserLinkWithImage';
import ProjectStatus from '../ProjectStatus';
import ListTable from '../ListTable';
import { AppColors } from '../../constants';

const styles = {
  root: {
    color: '#000',
    textDecoration: 'none',
  },
  icon: {
    fontSize: 16,
    paddingTop: 6,
    paddingRight: 2,
  },
  collaboratorsCount: {
    color: AppColors.greyDark,
  },
};

const ProjectListTableRow = ({ classes, item }) =>
  <Link
    className={classes.root}
    to={`/project/${item.slug}`}
  >
    <Typography>
      <Grid
        container
        spacing={24}
        key={item.id}
        alignItems="center"
      >
        <Grid item sm={2} xs={4}>
          <ProjectStatus open={item.open} />
        </Grid>
        <Grid item sm={6} xs={6}>
          {item.name}
        </Grid>
        <Hidden xsDown>
          <Grid item xs={3}>
            <UserLinkWithImage user={item.user} />
          </Grid>
        </Hidden>
        <Grid item sm={1} xs={2} className={classes.collaboratorsCount}>
          <Grid container direction="row" alignItems="center">
            <Grid item>
              <PersonIcon className={classes.icon} />
            </Grid>
            <Grid item>
              {item.collaborator_count}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Typography>
  </Link>;

const ProjectListTable = ({ classes, projects, emptyItemMessage }) =>
  <ListTable
    items={projects}
    classes={classes}
    RowComponent={ProjectListTableRow}
    emptyItemMessage={emptyItemMessage}
  />;

export default withStyles(styles)(ProjectListTable);
