import React from 'react';

import Comment from './Comment';

const CommentList = ({ comments }) =>
  comments.length > 0 && comments.map(comment =>
    <Comment key={comment.id} comment={comment} />,
  );


export default CommentList;
