export const defaultState = {
  isLoading: false,
  userProjects: [],
  projects: [],
  project: {},
  form: {},
  errors: {},
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'PROPOSAL_GET_ALL_FULFILLED':
      return {
        ...state,
        projects: action.payload.projects,
        userProjects: action.payload.user_projects,
        isLoading: false,
      };
    case 'PROPOSAL_GET_ALL_PENDING':
      return {
        ...state,
        isSuccess: false,
        isLoading: true,
      };
    case 'PROPOSAL_GET_FULFILLED':
      return {
        ...state,
        project: action.payload.project,
        form: action.payload.project,
        isLoading: false,
      };
    case 'PROPOSAL_GET_PENDING':
      return {
        ...state,
        isLoading: true,
      };
    case 'PROPOSAL_CREATE_FULFILLED':
      return {
        ...state,
        errors: {},
        project: action.payload.project,
        isSuccess: true,
        isLoading: false,
      };
    case 'PROPOSAL_CREATE_PENDING':
      return {
        ...state,
        isLoading: true,
      };
    case 'PROPOSAL_CREATE_REJECTED':
      return {
        ...state,
        errors: action.payload,
        isLoading: false,
      };
    case 'PROPOSAL_UPDATE_FULFILLED':
      return {
        ...state,
        errors: {},
        isSuccess: true,
      };
    case 'PROPOSAL_UPDATE_PENDING':
      return {
        ...state,
      };
    case 'PROPOSAL_UPDATE_REJECTED':
      return {
        ...state,
        errors: action.payload,
        isLoading: false,
      };
    case 'PROPOSAL_CHANGE':
      return {
        ...state,
        isSuccess: false,
      };
    case 'SUBMIT_LOGOUT_FULFILLED':
      return {
        ...state,
        userProjects: [],
      };
    default:
      return state;
  }
};
