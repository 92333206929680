import {
  destroy,
  post,
  put,
} from '.';

const setSpeciesConnections = collaborator => ({
  ...collaborator,
  species_connections_attributes: collaborator.species.map(connection => ({
    ...connection,
    species_id: connection.name.value,
  })),
});

export const acceptCollaborator = collaboratorId => post(`/collaborators/${collaboratorId}/accept`);
export const createCollaborator = collaborator => post('/collaborators', setSpeciesConnections(collaborator));
export const editCollaborator = collaborator => put(`/collaborators/${collaborator.id}`, setSpeciesConnections(collaborator));
export const destroyCollaborator = collaboratorId => destroy(`/collaborators/${collaboratorId}`);
