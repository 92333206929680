import { connect } from 'react-redux';

import {
  projectGet,
} from '../../actions/projectActions';

import {
  collaboratorLeave,
} from '../../actions/collaboratorActions';

import Project from './Project';

const mapStateToProps = state => ({
  ...state.userReducer,
  ...state.projectReducer,
});

const mapDispatchToProps = {
  projectGet,
  collaboratorLeave,
};

export default connect(mapStateToProps, mapDispatchToProps)(Project);
