import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { Formik, Form } from 'formik';
import { withStyles } from '@material-ui/core/styles';

const style = {
  replyButton: {
    textTransform: 'uppercase',
    marginTop: 20,
    fontWeight: 500,
    fontSize: 14,
    cursor: 'pointer',
  },
};

function CommentForm({ classes, isReply, comment, project, commentCreate, handleNewComment }) {
  const [isReplyVisible, setIsReplyVisible] = useState(!isReply);

  const commentId = comment ? comment.id : undefined;
  const parentId = project ? project.id : undefined;

  const initialValues = {
    comment_id: commentId,
    project_id: parentId,
    body: '',
  };

  function showReply() {
    setIsReplyVisible(true);
  }

  function onSubmit(values, { resetForm }) {
    commentCreate(values).then((response) => {
      resetForm(initialValues);
      setIsReplyVisible(!isReply);
      handleNewComment(response.value.comment, commentId);
    });
  }

  function onValidate(values) {
    const errors = {};
    if (!values.body) {
      errors.body = 'Required';
    }
    return errors;
  }

  return (
    <Formik
      initialValues={initialValues}
      validate={onValidate}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        handleChange,
      }) => (
        isReplyVisible ?
          <Form>
            <FormControl margin="normal" required fullWidth>
              <TextField
                name="body"
                label={isReply ? 'Reply to comment...' : 'Write new comment...'}
                value={values.body}
                onChange={handleChange}
                error={!!errors.body}
                helperText={errors.body}
                variant="outlined"
              />
            </FormControl>
            <Grid
              container
              direction="row-reverse"
              justify="flex-start"
            >
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                >Comment</Button>
              </Grid>
            </Grid>
          </Form>
          :
          <Link
            className={classes.replyButton}
            color="secondary"
            onClick={showReply}
          >Reply</Link>
      )}
    </Formik>
  );
}

export default withStyles(style)(CommentForm);
