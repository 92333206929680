import {
  get,
  post,
  put,
} from '.';

export const getProjects = () => get('/projects');
export const getProject = projectId => get(`/projects/${projectId}`);
export const createProject = project => post('/projects', { project });
export const updateProject = project => put(`/projects/${project.slug}`, { project });
export const postProjectContact = (projectId, body) => post(`/projects/${projectId}/contact`, { body });
