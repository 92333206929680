import { alertSignInRequired } from '../actions/authActions';
import { notifyError, notifyError404 } from '../actions/notificationActions';

const handleErrorsSymbol = Symbol('handleErrors');
const isNestedThunkSymbol = Symbol('isNestedThunk');

function shouldHandleError(action) {
  if (typeof action !== 'function') {
    return false;
  }
  if (typeof action[handleErrorsSymbol] === 'boolean') {
    return action[handleErrorsSymbol];
  }
  return action[isNestedThunkSymbol] !== true;
}


function createThunkErrorHandlerMiddleware({
  onError,
}) {
  return ({ dispatch }) => {
    function errorHandler(err) {
      const result = onError(err);
      if (typeof result === 'function') {
        // no recursive error handler calls;
        result[handleErrorsSymbol] = false;
        dispatch(result);
        return Promise.reject(err);
      }
      return result;
    }

    function wrapErrorHandling(fn) {
      return (...args) => {
        let result;
        try {
          result = fn(...args);
        } catch (err) {
          // sync error in reducer within a thunk
          errorHandler(err);
        }
        if (result instanceof Promise) {
          // async error in thunk
          return result.then(
            value => value,
            e => errorHandler(e),
          );
        }
        return result;
      };
    }

    return next => (action) => {
      let act = action;
      if (shouldHandleError(action)) {
        act = wrapErrorHandling(action);
      }
      return next(act);
    };
  };
}

function myErrorHandler(err) {
  if (err.status === 500) {
    return notifyError;
  }
  if (err.status === 404) {
    return notifyError404;
  }
  if (err.status === 401) {
    return alertSignInRequired();
  }
  return Promise.reject(err);
}

export default createThunkErrorHandlerMiddleware({ onError: myErrorHandler });
