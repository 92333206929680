import { combineReducers } from 'redux';
import institutionReducer from './institutionReducer';
import projectReducer from './projectReducer';
import userReducer from './userReducer';
import searchReducer from './searchReducer';
import speciesCategoryReducer from './speciesCategoryReducer';
import notificationReducer from './notificationReducer';

export default combineReducers({
  institutionReducer,
  notificationReducer,
  projectReducer,
  userReducer,
  searchReducer,
  speciesCategoryReducer,
});
