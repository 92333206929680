import React from 'react';

import Snackbar from '@material-ui/core/Snackbar';

const Notification = ({ message, isVisible, closeNotification }) =>
  <Snackbar
    open={isVisible}
    autoHideDuration={6000}
    onClose={closeNotification}
    ContentProps={{
      'aria-describedby': 'message-id',
    }}
    message={<span id="message-id">{message}</span>}
  />;

export default Notification;
