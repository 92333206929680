import { connect } from 'react-redux';

import {
  collaboratorEdit,
} from '../../actions/collaboratorActions';

import CollaboratorProgressEdit from './CollaboratorProgressEdit';

const mapDispatchToProps = {
  collaboratorEdit,
};

export default connect(null, mapDispatchToProps)(CollaboratorProgressEdit);
