import { AppColors } from '../../constants';

export default {
  '@global': {
    p: {
      margin: 0,
    },
    a: {
      color: AppColors.linkColorMain,
    },
    body: {
      fontFamily: [
        'Work Sans',
        'sans-serif',
      ].join(','),
    },
    img: {
      maxWidth: '100%',
    },
  },
};
