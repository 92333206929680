import { connect } from 'react-redux';

import {
  projectsGetAll,
} from '../../actions/projectActions';

import ProjectList from './ProjectList';

const mapStateToProps = state => ({
  ...state.userReducer,
  ...state.projectReducer,
});

const mapDispatchToProps = {
  projectsGetAll,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectList);
