import { connect } from 'react-redux';

import {
  institutionGetEdit,
  institutionUpdate,
} from '../../actions/institutionActions';

import InstitutionEdit from './InstitutionEdit';

const mapStateToProps = state => ({
  ...state.userReducer,
  ...state.institutionReducer,
});

const mapDispatchToProps = {
  institutionGetEdit,
  institutionUpdate,
};

export default connect(mapStateToProps, mapDispatchToProps)(InstitutionEdit);
