import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';

import Layout from '../Layout';
import Loading from '../../components/Loading';

import { getFullTitle } from '../../utils/title';

const RouteWithTitle = ({ location: { pathname }, authenticate, authenticated, userAuthLoading, title, component: Component, redirectTo = '/sign-in', hideSearch, marketing, fullWidth, ...rest }) => {
  document.title = getFullTitle(title);
  useEffect(() => {
    ReactGA.set({ page: pathname });
    ReactGA.pageview(pathname);
    window.scrollTo(0, 0);
  }, [pathname]);

  if (authenticate && userAuthLoading) {
    return (
      <Layout>
        <Loading isLoading />
      </Layout>
    );
  }

  return <Route
    render={props => (
      authenticate && !authenticated ? (
        <Redirect to={{
          pathname: redirectTo,
          state: { from: props.location, notify: true },
        }}
        />
      ) : (
        <Layout hideSearch={hideSearch} fullWidth={fullWidth} marketing={marketing}>
          <Component key={props.location.pathname} {...props} />
        </Layout>
      )
    )}
    {...rest}
  />;
};

const mapStateToProps = state => ({
  ...state.userReducer,
});

export default connect(mapStateToProps)(RouteWithTitle);
