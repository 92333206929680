export const defaultState = {
  user: {},
  users: [],
  errors: {},
  isLoading: false,
  isSuccess: false,
  userSignedIn: true,
  userAuthLoading: true,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'SUBMIT_SIGN_UP_PENDING':
      return {
        ...state,
        projects: action.payload,
        isLoading: true,
      };
    case 'SUBMIT_LOGIN_FULFILLED':
    case 'SUBMIT_SIGN_UP_FULFILLED':
      return {
        ...state,
        user: action.payload,
        errors: {},
        isLoading: false,
        isSuccess: true,
        userSignedIn: true,
      };
    case 'USER_GET_FULL_FULFILLED':
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
      };
    case 'USER_AUTH_FULFILLED':
      return {
        ...state,
        user: action.payload.user,
        userSignedIn: action.payload.signed_in,
        userAuthLoading: false,
      };
    case 'SUBMIT_LOGOUT_REJECTED':
      return {
        ...state,
        errors: action.payload.detail,
      };
    case 'NOTIFY_SIGN_IN':
    case 'SUBMIT_LOGOUT_FULFILLED':
      return {
        ...state,
        user: {},
        isLoading: false,
        isSuccess: false,
        userSignedIn: false,
      };
    case 'USER_GET_ALL_PENDING':
      return {
        ...state,
        isLoading: true,
      };
    case 'USER_GET_ALL_FULFILLED':
      return {
        ...state,
        isLoading: false,
        users: action.payload.users,
      };
    default:
      return state;
  }
};
