import * as React from 'react';
import { Link } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';

import AccountMenu from './AccountMenu';
import SearchBar from '../../components/SearchBar';
import Logo from '../../images/phylopsy-logo.svg';

const styles = {
  root: {
    background: 'white',
    color: 'black',
    boxShadow: 'none',
    marginBottom: 20,
  },
  grow: {
    flexGrow: 1,
  },
  logo: {
    backgroundImage: `url(${Logo})`,
    backgroundSize: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: 50,
    width: 150,
  },
  sectionDesktop: {
    minWidth: 250,
    display: 'flex',
  },
};

const Header = ({ classes, userSignedIn, user, submitLogout, marketing = false, hideSearch = false }) => (
  <AppBar className={classes.root} position="static">
    <Toolbar>
      <Link
        to={marketing ? '/' : '/project'}
        className={classes.logo}
      />
      {(!hideSearch && !marketing) &&
        <Hidden xsDown>
          <SearchBar />
        </Hidden>
      }
      <div className={classes.grow} />
      {userSignedIn ?
        <>
          { marketing ?
            <Button
              component={Link}
              to="/project"
              variant="contained"
              color="primary"
            >See Projects</Button>
            :
            <AccountMenu
              submitLogout={submitLogout}
              user={user}
            />
          }
        </>
        :
        <div className={classes.sectionDesktop}>
          <Button
            component={Link}
            to="/sign-in"
            color="inherit"
          >Sign In</Button>
          <div className={classes.grow} />
          <Button
            component={Link}
            to="/sign-up"
            variant="contained"
            color="primary"
            className={classes.accountButton}
          >Create Account</Button>
        </div>
      }
    </Toolbar>
  </AppBar>
);

export default withStyles(styles)(Header);
