import React from 'react';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import {
  Redirect,
} from 'react-router-dom';

import InstitutionForm from '../../components/InstitutionForm';

function InstitutionCreate({ isSuccess, institutionCreate }) {
  if (isSuccess) {
    return (
      <Redirect
        to={{
          pathname: '/institution',
        }}
      />
    );
  }

  return (
    <Grid container>
      <Grid item sm={3} />
      <Grid item sm={6}>
        <Typography variant="h2">Create insititution</Typography>
        <InstitutionForm
          handleSubmit={institutionCreate}
          isNewInstitution
        />
      </Grid>
    </Grid>
  );
}

export default InstitutionCreate;
