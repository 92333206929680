import React, { useState, useEffect } from 'react';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Formik, Form } from 'formik';

import {
  Redirect,
} from 'react-router-dom';

import PropTypes from 'prop-types';

import Link from '../../components/Link';
import Loading from '../../components/Loading';

const styles = {
  root: {
    marginTop: 100,
  },
  form: {
    marginTop: 15,
  },
};

function SignInForm({ classes, userSignedIn, userAuthLoading, submitLogin, location, alertSignInRequired }) {
  const [redirectTo, setRedirectTo] = useState('/project');

  useEffect(() => {
    if (location.state && location.state.notify) {
      alertSignInRequired(userSignedIn);
      setRedirectTo(location.state.from.pathname);
    }
  }, []);

  function handleSubmit(values) {
    return submitLogin(values.email, values.password);
  }

  if (userSignedIn && !userAuthLoading) {
    return (
      <Redirect
        to={{
          pathname: redirectTo,
        }}
      />
    );
  }

  return (
    <Grid container className={classes.root}>
      <Grid item sm={4} />
      <Grid item sm={4}>
        <Typography variant="h2">Sign in</Typography>
        <Typography>or <Link to="/sign-up/">create account</Link></Typography>
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          onSubmit={(values, actions) => {
            handleSubmit(values).then(() => {
              actions.setSubmitting(false);
            }).catch((response) => {
              actions.setErrors({ email: response[0].message });
              actions.setSubmitting(false);
            });
          }}
        >
          {({
            errors,
            isSubmitting,
            values,
            handleChange,
          }) => (
            isSubmitting ?
              <Loading isLoading />
              :
              <Form>
                <FormControl margin="dense" required fullWidth>
                  <TextField
                    type="email"
                    name="email"
                    label="Email address"
                    value={values.email}
                    onChange={handleChange}
                    error={!!errors.email}
                    helperText={errors.email}
                    variant="outlined"
                  />
                </FormControl>
                <FormControl margin="dense" required fullWidth>
                  <TextField
                    type="password"
                    name="password"
                    label="Password"
                    value={values.password}
                    onChange={handleChange}
                    error={!!errors.password}
                    helperText={errors.password}
                    variant="outlined"
                  />
                </FormControl>
                <FormControl margin="dense" required fullWidth>
                  <Typography><Link to="/password/forgot">Forgot password?</Link></Typography>
                </FormControl>
                <FormControl margin="normal" required>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Sign In
                  </Button>
                </FormControl>
              </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
}

SignInForm.propTypes = {
  submitLogin: PropTypes.func.isRequired,
};

export default withStyles(styles)(SignInForm);
