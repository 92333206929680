import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import {
  Link as RouterLink,
} from 'react-router-dom';

import Link from '../../components/Link';
import BrainGearImage from '../../images/animal-cognition.svg';
import OctopusImage from '../../images/assets/octopus.png';
import BonoboImage from '../../images/assets/bonobo.png';
import BeeImage from '../../images/assets/bee.png';
import { containerStyle } from '../../styles/container';

const styles = theme => ({
  container: {
    ...containerStyle(theme),
    flexGrow: 1,
  },
  headerContainer: {
    [theme.breakpoints.up('sm')]: {
      backgroundImage: `url(${BrainGearImage})`,
      backgroundSize: 700,
      backgroundRepeat: 'no-repeat',
      backgroundPositionX: 'calc(50% - 400px)',
    },
  },
  headerImage: {
    [theme.breakpoints.up('sm')]: {
      minHeight: 600,
    },
  },
  heroText: {
    fontSize: 55,
    lineHeight: '65px',
  },
  subtitle: {
    marginTop: 30,
    marginBottom: 30,
    fontSize: 22,
    lineHeight: '28px',
    fontWeight: 300,
  },
  heroImage: {
    width: '100%',
  },
  processHeader: {
    fontSize: 36,
    textAlign: 'center',
  },
  processText: {
    fontSize: 18,
    marginTop: 0,
    fontWeight: 300,
  },
  processSection: {
    marginTop: 40,
  },
  actionSection: {
    marginTop: 60,
    marginBottom: 20,
  },
});

const Home = ({ classes }) => (
  <div>
    <div className={classes.headerContainer}>
      <div className={classes.container}>
        <Grid container alignItems="center">
          <Grid item sm={6} className={classes.headerImage}>
            <Hidden smUp>
              <img src={BrainGearImage} alt="Brain Gear" className={classes.heroImage} />
            </Hidden>
          </Grid>
          <Grid item sm={5} xs={12}>
            <Typography variant="h1" className={classes.heroText}>Explore the impossible.</Typography>
            <Typography variant="body1" className={classes.subtitle}>Connect with other researchers around the world and push the boundaries of studies in animal cognition. PhyloPsy is a market place for ideas, a collaborative tool, and a virtual community where cognition researchers, animal managers, and phylogeneticists can make discoveries together they can’t make alone.&nbsp;
              <Link
                to="/about"
              >Read more</Link>
            </Typography>
            <Button
              component={RouterLink}
              to="/project"
              variant="contained"
              color="secondary"
              size="large"
            >Get Started</Button>
          </Grid>
        </Grid>
      </div>
    </div>

    <div className={classes.container}>
      <Grid container className={classes.processSection} spacing={40}>
        <Grid item sm={4} xs={12}>
          <img src={BeeImage} alt="Bonobo" className={classes.heroImage} />
          <Typography variant="h2" className={classes.processHeader}>Propose</Typography>
          <Typography variant="body1" className={classes.processText}>Do you have a question about animal cognition that you have always wanted to answer but do not have access to the right animals? Do you manage access to a population of animals and are you willing to collaborate with other researchers? Propose your project&nbsp;
            <Link
              to="/sign-up"
            >here</Link>.
          </Typography>
        </Grid>
        <Grid item sm={4} xs={12}>
          <img src={OctopusImage} alt="Octopus" className={classes.heroImage} />
          <Typography variant="h2" textAlign="center" className={classes.processHeader}>Find</Typography>
          <Typography variant="body1" className={classes.processText}>Are you looking for a certain animal species or population to join your study? Are you a researcher with access to a population of animals looking for an interesting project to join? Does your team of cognition researcher need the expertise of a phylogeneticist? Find collaborators, animal populations, phylogeneticists&nbsp;
            <Link
              to="/project"
            >here</Link>.
          </Typography>
        </Grid>
        <Grid item sm={4} xs={12}>
          <img src={BonoboImage} alt="Bonobo" className={classes.heroImage} />
          <Typography variant="h2" textAlign="center" className={classes.processHeader}>Collaborate</Typography>
          <Typography variant="body1" className={classes.processText}>Are you ready to get started but do not know where to begin? Find a step by step guide to large scale collaborative projects, including Memorandums of Understanding, Authorship Guidelines, and virtual collaborative tools&nbsp;
            <Link
              to="/collaborate"
            >here</Link>.
          </Typography>
        </Grid>
      </Grid>

      <Grid container alignItems="center" justify="center" className={classes.actionSection}>
        <Grid item>
          <Button
            component={RouterLink}
            to="/project"
            variant="contained"
            color="secondary"
            size="large"
          >Get Started</Button>
        </Grid>
      </Grid>
    </div>
  </div>
);

export default withStyles(styles)(Home);
