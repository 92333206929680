import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import {
  Redirect,
} from 'react-router-dom';

import UserForm from '../../components/UserForm';
import Link from '../../components/Link';

function SignUpForm({ isSuccess, userSignedIn, userAuthLoading, submitSignUp }) {
  const [createInstitution, setCreateInstitution] = useState(false);

  function handleSubmit(values) {
    setCreateInstitution(values.createInstitution);
    return submitSignUp(values);
  }

  if (isSuccess && createInstitution) {
    return (
      <Redirect
        to={{
          pathname: '/institution/new',
        }}
      />
    );
  }

  if (userSignedIn && !userAuthLoading) {
    return (
      <Redirect
        to={{
          pathname: '/project',
        }}
      />
    );
  }

  return (
    <Grid container>
      <Grid item sm={3} />
      <Grid item sm={6}>
        <Typography variant="h2">Create account</Typography>
        <Typography>or <Link to="/sign-in/">sign in</Link></Typography>
        <UserForm
          handleSubmit={handleSubmit}
          isNewUser
        />
      </Grid>
    </Grid>
  );
}

SignUpForm.propTypes = {
  submitSignUp: PropTypes.func.isRequired,
};

export default SignUpForm;
