import React from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Formik, Form } from 'formik';
import { Redirect } from 'react-router-dom';

import LinkBack from '../../components/LinkBack';

function InstitutionContact({ match: { params: { number: institutionId } }, institutionContact }) {
  return (
    <Formik
      initialValues={{
        institution_id: institutionId,
      }}
      validate={(values) => {
        const errors = {};
        if (!values.body) {
          errors.body = 'Required';
        }
        return errors;
      }}
      onSubmit={(values, { setStatus }) => {
        institutionContact(institutionId, values).then(() => {
          setStatus({ success: 'Email sent !' });
        });
      }}
    >
      {({
        values,
        errors,
        handleChange,
        status,
      }) => (
        <Grid container>
          <Grid item sm={3} />

          <Grid item sm={6} xs={12}>
            {status &&
              <Redirect
                to={{
                  pathname: `/institution/${institutionId}`,
                }}
              />
            }

            <LinkBack to={`/institution/${institutionId}`}>
              Back to institution
            </LinkBack>
            <Typography variant="h1">Message Institution</Typography>
            <Form>
              <FormControl margin="normal" required fullWidth>
                <TextField
                  multiline
                  name="body"
                  label="Message"
                  value={values.body}
                  onChange={handleChange}
                  error={!!errors.body}
                  helperText={errors.body}
                  variant="outlined"
                  rows={5}
                />
              </FormControl>
              <FormControl margin="normal" required>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Send Message
                </Button>
              </FormControl>
            </Form>
          </Grid>
        </Grid>
      )}
    </Formik>
  );
}

export default InstitutionContact;
