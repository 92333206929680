import {
  createFormData,
  get,
  post,
  put,
  stringifySpecies,
} from '.';

export const getInstitutions = () => get('/institutions');
export const getInstitution = institutionId => get(`/institutions/${institutionId}`);
export const getInstitutionEdit = institutionId => get(`/institutions/${institutionId}/edit`);
export const createInstitution = institution => post('/institutions', createFormData(stringifySpecies(institution), 'institution'));
export const updateInstitution = institution => put(`/institutions/${institution.slug}`, createFormData(stringifySpecies(institution), 'institution'));
export const postInstitutionContact = (institutionId, body) => post(`/institutions/${institutionId}/contact`, { body });
