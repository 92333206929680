export const defaultState = {
  speciesCategories: [],
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'SPECIES_CATEGORY_GET_ALL_FULFILLED':
      return {
        ...state,
        speciesCategories: action.payload.species_categories,
        isLoading: false,
      };
    default:
      return state;
  }
};
