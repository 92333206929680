import { connect } from 'react-redux';

import {
  usersGetAll,
} from '../../actions/userActions';

import UserList from './UserList';

const mapStateToProps = state => ({
  ...state.userReducer,
});

const mapDispatchToProps = {
  usersGetAll,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
