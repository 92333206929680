/* eslint-disable no-underscore-dangle */

import React, { useState } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CancelIcon from '@material-ui/icons/Cancel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';

import SpeciesInput from './SpeciesInput';

const styles = {

};

function newSpecies() {
  return {
    tempId: Math.random() * 10000000,
    name: '',
    amount: '',
  };
}

function SpeciesForm({ classes, species: initSpeciesList, speciesSearch, handleChange }) {
  const [speciesList, setSpeciesList] = useState([...initSpeciesList || [newSpecies()]]);
  if (speciesList.length === 0) {
    setSpeciesList([newSpecies()]);
  }

  function handleSpeciesChange(index) {
    return (newValue) => {
      const newSpeciesList = [...speciesList];
      newSpeciesList[index] = newValue;

      setSpeciesList(newSpeciesList);
      handleChange(newSpeciesList.filter(species => species.name !== '' || species.amount !== ''));
    };
  }

  function addSpecies() {
    const newSpeciesList = [...speciesList, newSpecies()];
    setSpeciesList(newSpeciesList);
  }

  function removeSpecies(index) {
    handleSpeciesChange(index)({
      ...speciesList[index],
      _destroy: true,
    });
  }

  return (
    <div className={classes.search}>
      {speciesList.filter(species => !species._destroy).map((species, index) =>
        <Grid
          key={species.id || species.tempId}
          container
          spacing={8}
          justify="space-between"
          alignItems="center"
        >
          <Grid item xs={11}>
            <SpeciesInput
              species={species}
              speciesSearch={speciesSearch}
              handleChange={handleSpeciesChange(index)}
            />
          </Grid>
          <Grid item xs={1}>
            <IconButton color="secondary" onClick={() => removeSpecies(index)}>
              <CancelIcon />
            </IconButton>
          </Grid>
        </Grid>,
      )}
      <Button color="secondary" onClick={addSpecies}>
        + Add another species
      </Button>
    </div>
  );
}


export default withStyles(styles)(SpeciesForm);
