import { connect } from 'react-redux';

import {
  userContact,
  userGet,
} from '../../actions/userActions';

import UserContact from './UserContact';

const mapDispatchToProps = {
  userContact,
  userGet,
};

export default connect(null, mapDispatchToProps)(UserContact);
