

import React, { useState, useEffect, useRef } from 'react';
import { findDOMNode } from 'react-dom';

import { Select as MuiSelect } from '@material-ui/core';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

function Select({ label, value, error, name, options, disabled = false, handleChange }) {
  const inputLabel = useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);
  useEffect(() => {
    // eslint-disable-next-line react/no-find-dom-node
    setLabelWidth(findDOMNode(inputLabel.current).offsetWidth);
  }, []);

  return (
    <FormControl error={!!error} margin="normal" variant="outlined" fullWidth disabled={disabled}>
      <InputLabel ref={inputLabel} shrink={value} htmlFor={name}>
        {label}
      </InputLabel>
      <MuiSelect
        value={value}
        onChange={handleChange}
        input={<OutlinedInput notched={value} labelWidth={labelWidth} name={name} id={name} />}
        name={name}
      >
        {options.map(option =>
          <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>,
        )}
      </MuiSelect>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}

export default Select;
