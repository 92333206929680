import {
  createComment,
} from '../api/commentApi';

// eslint-disable-next-line import/prefer-default-export
export const commentCreate = comment => dispatch =>
  dispatch({
    type: 'COMMENT_CREATE',
    payload: createComment(comment),
  });
