import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Dropzone from 'react-dropzone';
import Avatar from '../Avatar';

const styles = {
  root: {
    marginBottom: 10,
  },
  avatar: {
    float: 'left',
    marginRight: 10,
    width: 60,
    height: 60,
  },
  icon: {
    fontSize: 65,
    marginTop: 20,
  },
};

const FileDrop = ({ classes, form, handleFile, type }) =>
  <Dropzone
    onDrop={handleFile}
    accept="image/*"
  >
    {({ getRootProps, getInputProps, isDragActive }) => (
        <div
          className={classes.root}
          {...getRootProps()}
        >
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            <Grid item>
              <Avatar
                alt={form.name}
                className={classes.avatar}
                imageUrl={form.image_url}
                type={type}
              />
            </Grid>
            <Grid item>
              <input {...getInputProps()} />
              {
                isDragActive ?
                  <Typography>Drop files here...</Typography> :
                  <Button
                    variant="contained"
                    color="secondary"
                  >Choose Image</Button>
              }
            </Grid>
          </Grid>
        </div>
    )}
  </Dropzone>;

export default withStyles(styles)(FileDrop);
