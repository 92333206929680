/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable consistent-return */
import axios from 'axios';

axios.defaults.baseURL = '/api/';
axios.defaults.headers.post.Accept = 'application/json';
axios.defaults.headers.post['Content-Type'] = 'application/json';

const CancelToken = axios.CancelToken;
const cancelList = {};

const handleResponse = request =>
  request
    .then(response => response.data)
    .catch((error) => {
      if (axios.isCancel(error)) { return; }
      if ([500, 404, 401].includes(error.response.status) && !error.response.config.url.includes('sign_in')) {
        return Promise.reject(error.response);
      }
      if (error.response) {
        return Promise.reject(error.response.data);
      } else if (error.request) {
        return Promise.reject({ detail: 'Something went wrong' });
      } else {
        return Promise.reject({ detail: error.message });
      }
    });

const getObjectKey = (key, objectName) => (objectName ? `${objectName}[${key}]` : key);

export const createFormData = (object, objectName) => Object.keys(object).reduce((formData, key) => {
  formData.append(getObjectKey(key, objectName), object[key]);
  return formData;
}, new FormData());

export function get(endpoint, params, cancelkey) {
  let request = axios.get(endpoint, params);
  if (cancelkey) {
    if (cancelList[cancelkey]) {
      cancelList[cancelkey].cancel('Operation canceled by the user.');
    }
    const source = CancelToken.source();
    cancelList[cancelkey] = source;
    request = axios.get(endpoint, {
      cancelToken: source.token,
    });
  }
  return handleResponse(request);
}
export function post(endpoint, body) {
  return handleResponse(axios.post(endpoint, body));
}
export function put(endpoint, body) {
  return handleResponse(axios.put(endpoint, body));
}
export function destroy(endpoint) {
  return handleResponse(axios.delete(endpoint));
}

export const stringifySpecies = resource => ({
  ...resource,
  species_connections_attributes: JSON.stringify(
    resource.species_connections_attributes.map(connection => ({
      ...connection,
      species_id: connection.name.value,
    })),
  ),
});
