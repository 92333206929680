import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import {
  Redirect,
} from 'react-router-dom';

import Loading from '../../components/Loading';
import UserForm from '../../components/UserForm';
import UserInstitutions from './UserInstitutions';

const styles = {};

function UserEditForm({ errors, userSignedIn, userGetFull, submitUserEdit }) {
  const [isLoading, setIsLoading] = useState(true);
  const [userForm, setUserForm] = useState(null);

  useEffect(() => {
    userGetFull().then(({ value }) => {
      setUserForm({
        ...value.user,
        species_connections_attributes: value.user.species,
      });
      setIsLoading(false);
    });
  }, []);

  function handleSubmit(values) {
    return submitUserEdit(values);
  }

  if (isLoading) {
    return <Loading isLoading={isLoading} />;
  }

  if (!userSignedIn) {
    return (
      <Redirect
        to={{
          pathname: '/project',
        }}
      />
    );
  }

  if (isLoading) return <Loading isLoading />;

  return (
    <Grid container>
      <Grid item sm={3} />
      <Grid item sm={6}>
        <UserInstitutions institutions={userForm.institutions} />
        <Typography variant="h2">Account settings</Typography>
        <Loading
          isLoading={isLoading}
        >
          <UserForm
            form={userForm}
            errors={errors}
            handleSubmit={handleSubmit}
          />
        </Loading>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(UserEditForm);
