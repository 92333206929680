import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import Link from '../../components/Link';

import CooperationImage from '../../images/assets/cooperation.jpg';

import { containerStyle } from '../../styles/container';

const styles = theme => ({
  container: {
    ...containerStyle(theme),
    flexGrow: 1,
  },
  question: {
    marginBottom: 10,
    marginTop: 20,
    lineHeight: 1.5,
  },
  answer: {
    marginBottom: 20,
    fontSize: 18,
    fontWeight: 300,
  },
});

function Collaborate({ classes }) {
  return (
    <div className={classes.container}>
      <Grid container alignItems="center">
        <Grid item sm={2} />
        <Grid item sm={8}>
          <Typography variant="h1" className={classes.heroText}>Collaboration</Typography>
          <img src={CooperationImage} alt="Question methods" className={classes.image} />
          <Typography variant="h2" className={classes.question}>
            How do I find collaborators?
          </Typography>
          <Typography variant="body1" className={classes.answer}>
            You can search the PhyloPsy website and explore the bio pages of everyone who has joined the site. You can then use the contact button on their page to request they join a project you have proposed (i.e. by sending the link to the proposal page).  They can then request to join and you can accept them into your team.
          </Typography>

          <Typography variant="h2" className={classes.question}>
            How do I join an ongoing project?
          </Typography>
          <Typography variant="body1" className={classes.answer}>
            By creating an account on the PhyloPsy website, you can search the projects that members have proposed.  Once a project leader creates a project proposal, others can request to join the project until the project leader closes the project to new collaborators.
          </Typography>

          <Typography variant="h2" className={classes.question}>
            How do I find populations of animals?
          </Typography>
          <Typography variant="body1" className={classes.answer}>
            You can search the institutions who manage animal populations that have been registered on the PhyloPsy website.  Each institution’s page describes the animals they manage and what types of research they allow. Institutions may also have more detailed research guidelines on the kind of collaborations and research are allowed at their facility.  Each institution’s page also includes a contact button you can click and use to send a message to inquire more about how to establish a collaboration.
          </Typography>

          <Typography variant="h2" className={classes.question}>
            How do I make a collaboration agreement between members of my team?
          </Typography>
          <Typography variant="body1" className={classes.answer}>
            When you propose a project you will be prompted to create a Memorandum of Understanding or MoU.  This is a non-legally binding agreement that is meant to spell out the expectations of each collaborator from the project leader as well as what collaborators can expect from the project leader. We provide <Link external target="_blank" to="https://docs.google.com/document/d/1VMFElhXdQ68UB0g8On6GI5V68rGVt7Zb/edit">guidelines</Link> on how to create an MoU that you can use as a template if you decide to propose a project and take on the role of project leader.
          </Typography>

          <Typography variant="h2" className={classes.question}>
            How should teams share authorship?
          </Typography>
          <Typography variant="body1" className={classes.answer}>
            This is a critical question that must be agreed upon before any collaboration begins.  We strongly suggest that authorship guidelines are outlined in the MoU for each proposed project.  Each project leader will need to decide what form of collaboration will warrant authorship and how the order of authors will be determined.  One example is to provide each lab participating in your collaboration with two authorship spots – one for the P.I and another for the student who helps collect the actual data.  Each project leader will need to explicitly state their authorship offer in the proposal and MoU to assure they attract collaborators and there is no conflict on the team as the project nears completion.  Many research academic societies also offer guidelines on what might constitute authorship.
          </Typography>

          <Typography variant="h2" className={classes.question}>
            What ethics guidelines should I use?
          </Typography>
          <Typography variant="body1" className={classes.answer}>
            Each researcher must follow the welfare guidelines of the country in which they are carrying out research as well and the rules for animal research at the institution with which they are affiliated (e.g. in the U.S. institutions require a committee known as IUCAC to approve all animal research).
          </Typography>

          <Typography variant="h2" className={classes.question}>
            How do I share my methodology with others?
          </Typography>
          <Typography variant="body1" className={classes.answer}>
            Each project leader should send their collaborators a demonstration video, and then collaborators should send back a demonstration video from their species for approval before beginning data collection.
          </Typography>
          <Typography variant="body1" className={classes.answer}>
            Project leaders should create a gmail account for the project (selfcontrol@gmail.com) and share with project members. This will allow project leaders to create a youtube channel for their project and provide login and password to project members.  Project members can upload their videos and the project leader can review the methodology before data collection begins and throughout the duration of the project.
          </Typography>

          <Typography variant="h2" className={classes.question}>
            How do I communicate with my team when collaborators are in lots of different locations?
          </Typography>
          <Typography variant="body1" className={classes.answer}>
            There are many free communication applications available today.  For example, one on one communication can occur via <Link external target="_blank" to="https://skype.com/">Skype</Link> or <Link external target="_blank" to="https://zoom.com/">Zoom</Link>.  Larger conference calls can be held internationally via <Link external target="_blank" to="https://freeconferencecall.com">FreeConferenceCall.com</Link>.  Other services may be available in the countries in which you work.
          </Typography>

          <Typography variant="h2" className={classes.question}>
            What communication tools should we use to coordinate day to day research efforts?
          </Typography>
          <Typography variant="body1" className={classes.answer}>
            If you have a large group of collaborators, email will quickly become unwieldy.  Services like <Link external target="_blank" to="https://slack.com/">Slack</Link> and <Link external target="_blank" to="https://asana.com/">Asana</Link> can provide a group of collaborators a much more efficient means for efficient communication by creating a topic specific message board.  Groups working on a specific problem, population or species can create a forum that is dedicated to the problem they are solving and all relevant messages can easily be read and responded to.  <Link external target="_blank" to="https://basecamp.com/">Basecamp</Link> is a project management software designed to help large groups of people communicate and track progress on different but related tasks.
          </Typography>

          <Typography variant="h2" className={classes.question}>
            Where should we store our data?
          </Typography>
          <Typography variant="body1" className={classes.answer}>
            There are many solutions to this problem via cloud storage.  For example documents, spreadsheets and powerpoints can be created, shared and edited by all project members who are invited by project leaders.
          </Typography>
          <Typography variant="body1" className={classes.answer}>
            Video data is more difficult to store online because of file size.  However, sample videos can be shared via <Link external target="_blank" to="https://youtube.com/">YouTube</Link> as detailed above so that team members can make sure they are using the correct procedures as they test a variety of animals in different locations.
          </Typography>

        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(Collaborate);
